import eastLothianLogo from '../../images/Authorities/east_lothian.png';
import eastLothianaAlternativeLogo from '../../images/Authorities/east_lothian_blue.png';
import glasgowLogo from '../../images/Authorities/glasgow.png';
import shropshireLogo from '../../images/Authorities/shropshire.png';
import hackneyLogo from '../../images/Authorities/hackney.png';
import hackneyaAlternativeLogo from '../../images/Authorities/hackney_white.png';

import musselburgh from '../../images/LandingPage/Locations/musselburgh.png'
import north_berwick from '../../images/LandingPage/Locations/north-berwick.png'
import shropshire from '../../images/LandingPage/Locations/shropshire.png'
import glasgow from '../../images/LandingPage/Locations/glasgow.jpg'
import hackney from '../../images/LandingPage/Locations/hackney.jpg'

export const TOP_OF_PAGE = "TopOfPage";

export const LANDING_PAGE_ANCHORS = Object.freeze({
  TOP_OF_PAGE: 'TopOfPage',
  INTRO_VIDEO: 'IntroVideo',
  MORE_DETAIL: 'MoreDetail',
  LOCATIONS: 'Locations',
  HOW_TO_SING_UP: 'HowToSingUp',
  FAQ: 'FAQ',
})

export const LANDING_PAGE_NAVIGATION_BUTTONS = [
  {
    targetName: LANDING_PAGE_ANCHORS.INTRO_VIDEO,
    label: "Intro Video",
  },
  {
    targetName: LANDING_PAGE_ANCHORS.MORE_DETAIL,
    label: 'More Detail',
  },
  {
    targetName: LANDING_PAGE_ANCHORS.LOCATIONS,
    label: 'Locations',
  },
  {
    targetName: LANDING_PAGE_ANCHORS.HOW_TO_SING_UP,
    label: 'How to sign up',
  },
  {
    targetName: LANDING_PAGE_ANCHORS.FAQ,
    label: 'FAQ',
  },
];

export const LANDING_PAGE_MENU_HEIGHT = 64;
export const MIN_SCROLL_PIXELS_COUNT_TO_SHOW_SCROLL_BUTTON = 200;

export const SCROLLER_DEFAULT_OFFSET = (-1) * LANDING_PAGE_MENU_HEIGHT;
export const SCROLLER_CONFIG = {
  duration: 1000,
  delay: 100,
  smooth: true,
};

export const INTRO_VIDEO_URL = 'https://agile-streets-media-bucket.s3.eu-west-2.amazonaws.com/Agile+Streets+CK+Full_Final_03_with+subtitles.mp4';

export const PRIMARY_COLOR = '#007fac';
export const SECONDARY_COLOR = '#5d8338';

export const TITLE_BLOCK_STYLES = theme => ({
  titleWrapper: {
    position: 'relative',
  },
  titleBackground: {
    position: 'absolute',
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: PRIMARY_COLOR,
    borderRadius: '0 40px 40px 0',
  },
  titleTextWrapper: {
    padding: '10px 15px 10px 0',
    position: 'relative',
    zIndex: 2,
    color: 'white',
    fontSize: 24,
    textAlign: 'justify',
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
      padding: 20,
    },
  },
  title: {
    textAlign: 'justify',
    fontSize: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  paragraph: {
    width: '100%',
    margin: '10px 0',
    textAlign: 'justify',
    fontSize: 22,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  titleBlockWrapper: {
    display: "block",
    height: 400,
    justify: "space-around",
    borderRadius: 75,
    marginTop: 100,
    paddingLeft: 120,
    paddingTop: 40,
    marginLeft: -67,
    backgroundColor: PRIMARY_COLOR,
    [theme.breakpoints.down("xs")]: {
      margin: 10,
      height: 200,
    },
  },
});

export const LINK_BUTTON_STYLES = {
  color: "white",
  backgroundColor: 'transparent',
  border: 'none',
  padding: 4,
  margin: '0 -4px',
  textDecoration: 'underline',
  textTransform: 'none',
  fontFamily: 'Comfortaa',
  '&:hover': {
    color: PRIMARY_COLOR,
    textDecoration: "none",
    backgroundColor: "white",
    borderRadius: 4,
  },
};

export const AUTHORITY_LOGO_STYLES = {
  position: 'absolute',
  borderRadius: 5,
  backgroundSize: 'calc(100% - 10px) calc(100% - 10px)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  '@media (max-width: 500px)': {
    borderRadius: 0,
    backgroundSize: '90% 90%',
  },
};

export const AUTHORITIES = Object.freeze({
  EAST_LOTHIAN: 'East Lothian',
  GLASGOW: 'Glasgow',
  SHROPSHIRE: 'Shropshire',
  HACKNEY: 'Hackney',
});

export const AUTHORITIES_CONFIG = {
  [AUTHORITIES.EAST_LOTHIAN]: {
    logoWidth: '25%',
    dialogLogo: eastLothianLogo,
    mapLogo: eastLothianaAlternativeLogo,
    maps: [north_berwick, musselburgh],
    locations: [
      'The Glebe, St Andrew Street, North Berwick, EH39 4NU',
      'Pomenade, Musselburgh, EH21 6JU',
      'Stoneybank Terrace, Musselburgh, EH21 6LY',
    ]
  },
  [AUTHORITIES.GLASGOW]: {
    logoWidth: '15%',
    dialogLogo: glasgowLogo,
    mapLogo: glasgowLogo,
    maps: [glasgow],
    locations: [
      'Prospecthill Circus, Glasgow, G42 OHS',
      '14 Terregles Ave, Glasgow, G41 5RA',
      'Park Street South & Park Circus, Glasgow, G3 6HG',
      '83 Broadholm St, Glasgow, G22 6SP',
    ]
  },
  [AUTHORITIES.SHROPSHIRE]: {
    logoWidth: '25%',
    dialogLogo: shropshireLogo,
    mapLogo: shropshireLogo,
    maps: [shropshire],
    locations: [
      'Oak Street Car Park, Oswestry, SY11 1LN',
      'Brownlow Street, Whitchurch, SY13 1AJ',
      'Abbey Foregate Car Park, Shrewsbury, SY2 6AH',
      'Severn Street Car Park, Bridgnorth, WV15 6BP',
    ]
  },
  [AUTHORITIES.HACKNEY]: {
    logoWidth: '25%',
    dialogLogo: hackneyLogo,
    mapLogo: hackneyaAlternativeLogo,
    maps: [hackney],
    locations: [
      '79 Lordship Park, London, N16 5UP',
      'Wordsworth Road (West), N16 8DD',
      '2 Londesborough Road, N16 8RH',
      '127 Clissold Cres, London, N16 9AT',
      'Defoe Road, London, N16 0EF',
    ]
  },
};
