// external
import React from "react";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// internal
import { PRIMARY_COLOR, LINK_BUTTON_STYLES } from "../constants";

const styles = (theme) => ({
  blueBackground: {
    textAlign: 'justify',
    marginTop: 20,
    paddingBottom: 20,
    backgroundColor: PRIMARY_COLOR,
    color: 'white',
    fontFamily: 'Comfortaa',
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    }
  },
  title: {
    color: 'white',
    fontSize: 25,
    fontWeight: "bold",
    paddingTop: '40px',
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  boldText: {
    fontSize: 22,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
    fontWeight: 'bold',
  },
  link: {
    ...LINK_BUTTON_STYLES,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  }
});

const FAQ = ({ classes }) => {
  return (
    <Grid container justifyContent="center" className={classes.blueBackground}>
      <Grid item xs={12} sm={10} container direction="column">
        <p className={classes.title}>
          FAQs
        </p>
        <p>
          Agile streets is a game-changing trial of new technology that will allow people without driveways to charge
          their cars at off-peak rates from late October 2021 until May 2022. The BEIS-funded trial is being led by
          Samsung Electronics in collaboration with&#160;
          <a href="https://www.connectedkerb.com/" className={classes.link}>Connected Kerb</a>,&#160;
          <a href="https://octopus.energy/business/" className={classes.link}>Octopus Energy</a>,&#160;
          <a href="https://pndc.co.uk/" className={classes.link}>The Power Networks Distribution Centre (PNDC)</a>,
          &#160;<a href="https://energysavingtrust.org.uk/" className={classes.link}>Energy Saving Trust</a>
          &#160;and&#160;
          <a href="https://www.slscorp.com/" className={classes.link}>SMETS Design Limited (known as SLS)</a>.
          and <a href="https://on.to" className={classes.link}>ONTO</a> - the project's electric car subscription partner.
        </p>
        <p>
          Connected Kerb, one of the UK's leading residential charging point provider, will be installing 100 electric
          car chargepoints on streets and car parks in Glasgow, Hackney (London), Shropshire, and East Lothian over
          autumn 2021 for the trial to kick off in late October. The new chargers, equipped with smart metering, will be
          installed in places close to residents who don’t have off-street parking.
        </p>
        <p>
          <span className={classes.boldText}>
            Why is smart metering on-street charging so critical to the switch to EV?
          </span><br/>
          Smart metering enables variable Time-of-Use energy billing and helps incentivise people to consume energy
          when it is cheapest and greenest. Currently, over 60% of the population either do not have a driveway or
          access to a parking where they can install a charger. Agile streets is bringing the benefits of smart meters
          to these people, allowing local residents and workers to save up to 40% when "ECO" charging
        </p>
        <p>
          <span className={classes.boldText}>
            What about people interested in joining the trial but do not currently own an EV?
          </span><br/>
          We have secured exclusive deals with our car subscription
          partner <a href="https://on.to" className={classes.link}>ONTO</a> for local eco-champions who want to switch
          to electric. Sign-up to the trial and tell us why you should be selected to be part of the Agile Streets
          journey and beneﬁt from up to 50% off a car subscription for the ﬁrst two months on selected models,
          subject to availability.
        </p>
        <p>
          <span className={classes.boldText}>
            Can I keep the car once the trial ends?
          </span><br/>
          Absolutely! Onto is an all-inclusive electric car subscription, and you can pause or cancel at any time.
        </p>
        <p>
          <span className={classes.boldText}>What will happen to the charge points after the trial?</span><br/>
          Once the project concludes ownership of the charge points will be offered (at no cost) to the local
          authorities so that they can be added to their local charging network.
        </p>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles))(FAQ);
