// external
import React from "react";
import {Grid} from "@material-ui/core";

export const TERMS_AND_CONDITIONS = [
  {
    title: "Article 1 (Purpose)",
    items: [
      {
        type: "p",
        content: (
          <span>
            This User Registration Agreement for Agile Streets (hereinafter
            "User Registration Agreement") aimsto set forth the rights,
            obligations and responsibilities of "Company" and "User" (defined
            separately)with respect to the User’s use of this website to
            register their interest in the Agile Streets Trial "AST") operated
            by Samsung Electronics (UK) Limited (hereinafter "Company") in
            partnership with Connected Kerb Limited (CK), the Energy Saving
            Trust (EST), University of Strathclyde, SMETS Design Limited (SLS
            Ltd) (“Our <strong>Partners</strong>”)
          </span>
        ),
      },
    ],
  },
  {
    title: "Article 2 (Definitions)",
    items: [
      {
        type: "p",
        content: `The AST is a game-changing trial of new technology that will allow people without driveways to charge
          their cars at off-peak rates.`,
      },
      {
        type: "p",
        content: `The AST website is a system constructed and operated by the Company for the following aims:`,
      },
      {
        type: "ol",
        items: [
          "To provide potential Users with information about the AST;",
          `To allow potential Users to express their interest by providing information about themselves, and about their
            driving habits;`,
        ],
      },
      {
        type: "p",
        content:
          "This information will be used by the company for the following purposes:",
      },
      {
        type: "ol",
        items: [
          `If the AST is oversubscribed we will use information provided to select trial users from the
            potential Users to ensure that we get a good balance of different types of user;`,
          `Once the User selection has been made we will contact Users to inform them whether
            they have been accepted onto the trial;`,
          `Users who have been selected will be sent a link to download our partner Connected
            Kerb’s mobile application. As part of the set-up process the User will need to accept
            Connected Kerb’s Terms and Conditions and create an account to take part in the AST;`,
          "If a User is not selected, we will inform the User by email and then delete all of that User’s data;",
          `For Users who are selected onto the trial we will share their registration data and contact
            details with our evaluation partners the University of Strathclyde and the Energy Saving
            Trust so that they can use the information provided as part of the trial evaluation, and to
            allow the evaluators to contact the Users to conduct further interviews and questionnaires
            for evaluation purposes;`,
          `In addition the company will contact Users during the trial to inform Users about any trial
            updates and will ask Users whether they would like to participate in various promotional
            events concerning the AST.`,
        ],
      },
      {
        type: "p",
        content: `Definitions of other terms used in this User Registration Agreement shall follow the relevant laws and
          regulations and commercial practices.`,
      },
    ],
  },
  {
    title:
      "Article 3 (Application and Revision of User Registration Agreement)",
    items: [
      {
        type: "p",
        content: `This User Registration Agreement shall be effective upon the consent of the User during
          User Registration.`,
      },
      {
        type: "p",
        content: `From time to time, the Company may revise this User Registration Agreement to promote
          the smooth operation of "AST." In the event that the Company revises this User Registration
          Agreement, it shall post the effective date and the reasons for revision along with the current
          school agreement on the front page of the "Agile Streets" website (agilestreets.com) for a
          period from seven (7) days before the effective date to the day before the effective date.
          Notwithstanding the foregoing, in the event of an emergency, the Company may post a
          public notice and apply the revised User Registration Agreement immediately.`,
      },
      {
        type: "p",
        content: `Any matters not specified in this User Registration Agreement, and the interpretation of the
          contents of this User Registration Agreement, shall follow the relevant laws, regulations and
          commercial practices.`,
      },
    ],
  },
  {
    title: "Article 4 (User Withdrawal, Disqualification and Completion)",
    items: [
      {
        type: "p",
        content: "User Withdrawal",
      },
      {
        type: "ol",
        items: [
          `The User, at any time, may withdraw from the service by giving notice of withdrawal to the
            Company by sending an e-mail to info@agilestreets.com. Notwithstanding the foregoing,
            in the event that the User fails to return the Test Equipment before his or her withdrawal,
            such withdrawal shall only be effective after the full return of the Test Equipment.`,
          `When the User gives a notice of withdrawal to the Company, the Company may go
            through a confirmation process.`,
          "One the User withdrawal is confirmed the Company will delete all User data that it holds.",
        ],
      },
      {
        type: "p",
        content: "User Disqualification",
      },
      {
        type: "p",
        content:
          "The Company may disqualify the User in any of the following circumstances:",
      },
      {
        type: "ol",
        items: [
          "When it is found that the User is a false subscriber",
          "When the User fails to comply with a testing request made by the Company for an extended period of time",
          "When the User violates any obligation of this Agreement",
        ],
      },
      {
        type: "p",
        content: `The Company shall not be liable for any damage to the User caused by his/her
          disqualification under Paragraph 4 of this provision.`,
      },
    ],
  },
  {
    title: "Article 5 (Protection of Information)",
    items: [
      {
        type: "p",
        content:
          "When registering, users will provide the following information:",
      },
      {
        type: "ul",
        items: [
          `Name, Email address; Expected mileage; Make of car; How often they charge their
            car per week; when they expect to use the trial charge points.`,
        ],
      },
      {
        type: "p",
        content: `Information provided by the User cannot be used for purposes other than the provision of
          the service and for testing purposes during service development, nor can said information
          be provided to a third party (with the exception of the Partners) without the prior consent of
          the relevant User, and the Company shall be liable for any violation of this requirement.
          Notwithstanding the foregoing, the following exceptions shall apply.`,
      },
      {
        type: "ol",
        items: [
          `In the event that information is provided in a form that does not reveal the identity of the
            individuals, for the purposes of statistics, academic research and marketing surveys`,
          `In the event that the information is provided to an affiliated company to enable the User to
            access an affiliated service (However, in such a case, the Company shall give prior notice
            to the User specifying the name of the affiliated company, the purpose of the provision,
            and the details of the information to be provided, and obtain the consent of the User.)`,
        ],
      },
      {
        type: "p",
        content: `The User, at any time, may make a request for access to and correction of his or her
          information that is under the control of the Company, and the Company shall have the
          obligation to take any necessary measures to fulfil such request without delay. If the User
          makes a request for the correction of an error, the Company shall not use the relevant
          information until such information is duly corrected.`,
      },
      {
        type: "p",
        content: `For the protection of information, the Company shall limit the number of personal
          information managers to a minimum, and shall be liable for any and all damages to the User
          resulting from the loss, theft, disclosure, falsification, etc. of his or her personal information
          caused by the Company’s intentional act or gross negligence.`,
      },
      {
        type: "p",
        content: `Any third party receiving information from the Company shall destroy the relevant
          information when the purpose of its collection and provision is achieved.`,
      },
    ],
  },
  {
    title: "Article 6 (Ownership of Copyrights and Limitation of Use)",
    items: [
      {
        type: "p",
        content: `Copyright and all other intellectual property rights to the works made by the Company shall
          be owned by the Company.`,
      },
      {
        type: "p",
        content: `The User shall not use information obtained during the use of the service, nor cause any
          third party to use such information for purposes other than sales of products or services, by
          means of reproduction, transmission, publication, distribution, broadcasting, or other
          methods without the prior approval of the Company.`,
      },
    ],
  },
  {
    title: "Article 7 (Investigation & Complaint Process)",
    items: [
      {
        type: "p",
        content: `If you have a complaint about material posted about the AST or the testing processes,
          please send an email to info@agilestreets.com. If you have a complaint about any aspect
          of the AST, please send an email to info@agilestreets.com. The Company cannot
          guarantee your issue will be dealt with promptly if any other mechanism is used.`,
      },
      {
        type: "p",
        content: `The Company may be bound by current or future legal statutes, such as the Regulation of
          Investigatory Powers Act 2000 to access, monitor, store, take copies of, or otherwise deal
          with the members’ data stored the service. Without limitation, you expressly authorise the
          Company to use personal data and other account information in connection with any such
          investigation, including disclosure to any third party authority that is considered to possess
          a legitimate interest in any such investigation or its outcome.`,
      },
    ],
  },
  {
    title: "Article 8 (Assignment)",
    items: [
      {
        type: "p",
        content: `The Company reserves the right to assign this contract at any time whether in whole or in
          part. You do not have this right but can cancel your contract after 1 year by contacting us
          at info@agilestreets.com`,
      },
    ],
  },
  {
    title: "Article 9 (Jurisdiction)",
    items: [
      {
        type: "p",
        content:
          "These Terms of Use are governed by English law and under the jurisdiction of English courts.",
      },
      {
        type: "p",
        content: `If any of these Terms of Use are determined to be invalid or unenforceable pursuant to
          applicable law including, but not limited to, the Limitation of Liability and the Disclaimer of
          Warranties clauses at the end of this document, then the invalid or unenforceable provision
          will be deemed as having been superseded by a valid, enforceable provision which most
          closely matches the intent of the original provision and the remainder of the Terms of Use
          shall continue in effect.`,
      },
    ],
  },
  {
    title: "Supplementary Provision",
    items: [
      {
        type: "p",
        content: (
          <span>
            This User Registration Agreement shall be effective from 1
            <sup>st</sup> of July, 2021.
          </span>
        ),
      },
    ],
  },
];

export const PRIVACY_POLICY = [
  {
    type: "p",
    content: "Effective: 11.08.2021",
  },
  {
    type: "p",
    content: (
      <span>
        Samsung Electronics (UK) Limited (
        <strong>“Samsung”, “we”, “us”, “our”</strong>) knows how important
        individual privacy is, and we strive to be clear about how we collect,
        use, disclose, transfer and store your information. This Privacy Policy
        describes the ways in which we collect and use your information as part
        of the Agile Streets Trial (the <strong>“Trial”</strong> or{" "}
        <strong>“AST”</strong>) conducted by Samsung in partnership with
        Connected Kerb Limited (CK), the Energy Saving Trust (EST), University
        of Strathclyde, SMETS Design Limited (SLS Ltd) (
        <strong>“Our Partners”</strong>). Samsung Electronics (UK) Limited is
        the data controller for the processing of personal data in relation to
        the Trial.
      </span>
    ),
  },
  {
    type: "h3",
    content: "What information do we collect about you?",
  },
  {
    type: "p",
    content: (
      <span>
        If you are a participant, we may collect and process the following
        information as part of the Trial (“<strong>Personal Data</strong>”):
      </span>
    ),
  },
  {
    type: "ul",
    items: [
      <span>
        Information you provided to Samsung when you registered your interest in
        the Trial via <a href="https://www.agilestreets.com">www.agilestreets.com</a>:
        Name, Email address;
      </span>,
      `Any communications that you send or deliver to us; and`,
      `Technical data, such as IP addresses and device IDs necessary to render the Portal and data 
          derived from cookies as explained in the section on cookies.`,
    ],
  },
  {
    type: "h3",
    content: "How do we use your Personal Data?",
  },
  {
    type: "p",
    content: `We process your Personal Data for the purposes set out below on the basis of your consent which we
        will ask for separately when you register your interest. You may withdraw your consent at any time
        by contacting us as specified in the Contact section of this Privacy Policy, however this may affect your
        ability to participate in the Trial and receive services through it. Withdrawing your consent will not
        affect the lawfulness of processing based on your consent before the withdrawal.`,
  },
  {
    type: "p",
    content:
      "If you are a participant, we use your Personal Data for the following purposes:",
  },
  {
    type: "ul",
    items: [
      `If the AST is oversubscribed we will use information provided to select trial users from the
          potential Users to ensure that we get a good balance of different types of user;`,
      `Once the User selection has been made we will contact Users to inform them whether they
          have been accepted onto the trial;`,
      `Users who have been selected will be sent a link to download our partner Connected Kerb’s
          mobile application. As part of the set-up process the User will need to accept Connected
          Kerb’s Terms and Conditions and create an account to take part in the AST;`,
      `If a User is not selected, we will inform the User by email and then delete all of that User’s
          data;`,
      `For Users who are selected onto the trial we will share their registration data and contact
          details with our evaluation partners the University of Strathclyde and Energy Saving Trust so
          that they can use the information provided as part of the trial evaluation, and to allow the
          evaluators to contact the Users to conduct further interviews and questionnaires for
          evaluation purposes;`,
      `For Users who have expressed an interest in a car subscription from our car subscription partner on.to,
          we will share their email address with on.to to allow on.to to contact the User to manage the car subscription
          sign-up process;`,
      `In addition Samsung and Our Partners will contact Users during the trial to inform Users about
          any trial updates and will ask Users whether they would like to participate in various
          promotional events concerning the AST.`,
      `To respond to your questions or requests for information; and`,
      `Otherwise where we have obtained your specific consent.`,
    ],
  },
  {
    type: "p",
    content: `We also use certain Personal Data for our legitimate interests, such as to safeguard the security of the
        services we provide.`,
  },
  {
    type: "h3",
    content: "To whom do we disclose your Personal Data?",
  },
  {
    type: "p",
    content: `We do not disclose your Personal Data to third parties for their own independent marketing or
        business purposes without your separate consent. However, we may share your Personal Data with
        the following entities:`,
  },
  {
    type: "ul",
    items: [
      `Limited numbers of Samsung employees that will have access to the information in
          connection with their job responsibilities or contractual obligations;`,
      `Samsung affiliates, for the purposes described above;`,
      `Our Project Partners to support the selection of trial participants and to invite Users to
          take part in the trial;`,
      `Our car subscription partner on.to (only for participants  who marked  on the registration form an interest in 
      a car subscription at a special rate from on.to);`,
      `Other parties: (i) if we are required to do so by law or legal process (such as a court order
            or subpoena); (ii) in response to requests by government agencies, such as law
            enforcement authorities; (iii) to establish, exercise or defend our legal rights; (iv) when
            we believe disclosure is necessary or appropriate to prevent physical or other harm; (v) in
            connection with an investigation of suspected or actual illegal activity; or (vi) in the event
            we sell or transfer all or a portion of our business or assets (including in the event of a
            merger, acquisition, reorganization, dissolution or liquidation); and`,
      `With other third parties when you consent to, or request such sharing.`,
    ],
  },
  {
    type: "h3",
    content: "How do we keep your Personal Data secure?",
  },
  {
    type: "p",
    content: `We have put in place appropriate physical and technical measures to safeguard the Personal Data we
        collect in connection with the Trial from accidental, unlawful or unauthorised destruction,
        interference, loss, alteration, access, disclosure or use.`,
  },
  {
    type: "h3",
    content: "International transfer of Personal Data",
  },
  {
    type: "p",
    content: "Personal data is not transferred outside of the UK and EEA.",
  },
  {
    type: "h3",
    content: "What are your rights?",
  },
  {
    type: "p",
    content: `Subject to limitations in applicable data protection laws, you have the right to request access to or
        rectification of the personal data which we hold about you. You also have the right to portability of or
        erasure of the Personal Data we hold about you and the right to object to or restrict the data
        processing we do about you, however, this may impact on your ability to participate in the Trial and
        receive services through it. We will comply with your request promptly and in accordance with the
        relevant legislation. If you would like to make any such request, please contact us as specified in the
        Contact section of this Privacy Policy.`,
  },
  {
    type: "p",
    content: `Please help us to ensure that the information we hold about you is accurate and up to date. If you
        think that any information we have about you is incorrect or incomplete, please contact us in the
        manner set out below. We will correct or update any information about you as soon as reasonably
        possible.`,
  },
  {
    type: "h3",
    content: "How long do we keep your Personal Data?",
  },
  {
    type: "p",
    content: `We will only keep your personal information as long as we need it for the reason that it was originally
        collected. Your information will be destroyed or erased from our systems when we don't need it
        anymore.`,
  },
  {
    type: "h3",
    content: "Cookies",
  },
  {
    type: "p",
    content: `Our website uses cookies to distinguish you from other users of our website. This helps us to provide
        you with a good experience when you browse our website and also allows us to improve our site.`,
  },
  {
    type: "p",
    content: `A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
        computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.
        We use the following cookies:`,
  },
  {
    type: "ul",
    items: [
      <span>
        <strong> Analytical or performance cookies. </strong>
        These allow us to recognise and count the number of visitors and to see how visitors move around our
        website when they are using it. This helps us to improve the way our website works, for example, by
        ensuring that users are finding what they are looking for easily.
      </span>,
      <span>
        <strong>Advertising / Targeting Cookies. </strong>
        These cookies gather information about your browser habits. They remember that you’ve visited
        our website and share this information with other organizations such as advertisers.
      </span>,
    ],
  },
  {
    type: "p",
    content: `You can find more information about the individual cookies we use and the purposes for which we
        use them in the table below:`,
  },
  {
    type: "table",
    items: [
      {
        data: ["Cookies", "Description", "Duration", "Type of cookie"],
      },
      {
        data: [
          <strong> Zendesk</strong>,
          `Customer Support Software.
              It puts all the user support
              interactions in one place, so
              communication is seamless,
              personal and efficient–which
              means more productive
              agents and satisfied
              customers.`,
          `Persistent.`,
          `Analytical or Performance`,
        ],
      },
      {
        data: [
          <strong> Google Analytics</strong>,
          `Google Analytics is a web
              analytics service offered by
              Google that tracks and
              reports website traffic,
              currently as a platform inside
              the Google Marketing
              Platform brand.`,
          `Persistent.`,
          `Analytical or Performance`,
        ],
      },
      {
        data: [
          <strong> Google API</strong>,
          `Google APIs is a set of
              application programming
              interfaces (APIs) developed
              by Google which allow
              communication with Google
              Services and their integration
              to other services. Examples
              of these include Search,
              Gmail, Translate or Google
              Maps. Third-party apps can
              use these APIs to take
              advantage of or extend the
              functionality of the existing
              services.`,
          `Persistent`,
          `Advertising / Targeting`,
        ],
      },
      {
        data: [
          <strong> ZD-suid</strong>,
          `Unique id that identifies the 
              user's session`,
          `Persistent`,
          `Analytical or Performance`,
        ],
      },
      {
        data: [
          <strong> ZD-store</strong>,
          `Registers whether the self-
            service-assistant Zendesk
            Answer Bot has been 
            displayed to the web site
            user.`,

          `Persistent`,
          `Analytical of Performance`,
        ],
      },
      {
        data: [
          ` _ga`,
          `This cookie is installed by 
              Google Analytics. The cookie
              is used to calculate visitor,
              session, campaign data and
              keep track of site usage for
              the site’s analytics report. The
              cookies store information
              anonymously and assigns a
              randomly generated number
              to identify unique visitors.`,
          `2 years`,
          `Analytical or Performance`,
        ],
      },
      {
        data: [
          ` _gid`,
          `This cookie is installed by 
              Google Analytics. The cookie
              is used to store information of
              how visitors use a website
              and helps in creating an
              analytics report of how the
              website is going. The data
              collected
              including
              the
              number of visitors, the source
              where they have come from
              and the pages visited in an
              anonymous form.`,

          `1 day`,
          `Analytical or Performance`,
        ],
      },
      {
        data: [
          <strong> ZD-buid</strong>,
          `Unique id that identifies the
              user on recurrung visits.`,
          `Persistent`,
          `Analytical or Performance`,
        ],
      },
      {
        data: [
          <strong> ZD-currentTime</strong>,
          `Registers the date and time
            for the user's latest visit to
            the web site.`,
          `Session`,
          `Analytical or Performance`,
        ],
      },
      {
        data: [
          ` NID`,
          `This cookie is used to profile 
              based on user’s interest and
              display personalized ads to
              the users.`,
          `6 months`,
          `Advertising / Targeting`,
        ],
      },
      {
        data: [
          <strong> r/collect</strong>,
          `This coockie is used to send
              data to Google Analytics
              about the visitor's device and
              behavior. It tracks the
              visitor across devices and
              marketing channels`,
          `Session`,
          `Advertising / Targeting`,
        ],
      },
    ],
  },
  {
    type: "p",
    content: `You can change your cookie preferences at any time by going to the cookies manager at the bottom
        of the page on the Agile Streets website portal.`,
  },
  {
    type: "p",
    content: `You can also block cookies by activating the setting on your browser that allows you to refuse the
        setting of all or some cookies. However, if you use your browser settings to block all cookies (including
        essential cookies) you may not be able to access all or parts of our website.`,
  },
  {
    type: "h3",
    content: "Contact",
  },
  {
    type: "p",
    content: `If you have any questions regarding this Privacy Policy, please contact us at:`,
  },
  {
    type: "p",
    content: (
      <span>Data Protection Officer<br/>
      Samsung Electronics (UK) Limited<br/>
      Samsung House, 2000 Hillswood Drive, Chertsey, Surrey KT16 0RS</span>
    ),
  },
  {
    type: "p",
    content: `Or`,
  },
  {
    type: "div",
    content: (
      <Grid container alignItems='center'>
        <span>Please email us at:&nbsp;</span>
        <a href="mailto:info@agilestreets.com">info@agilestreets.com</a>
        <span>&nbsp;and / or&nbsp;</span>
        <a href="mailto:dataprotection@samsung.com">
          dataprotection@samsung.com.
        </a>
      </Grid>
    ),
  },
  {
    type: "p",
    content: (
      <span>
        Samsung is regulated by the Information Commissioner’s Office (
        <strong>“ICO”</strong>). If you would like to report a concern or lodge
        a complaint in relation to Samsung’s handling of your personal data you
        can contact the ICO.
      </span>
    ),
  },
];
