// external
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// internal
import App from './App';
import store from './store';
import './style.css';

const MAIN_COLOR = '#0077c8';

const defaultTheme = createTheme();
const theme = createTheme({
  palette: {
    background: {
      default: "#fff"
    },
    primary: {
      light: '#009df5',
      main: MAIN_COLOR,
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiGridListTile: {
      tile: {
        display: 'flex',
        alignItems: 'center',
      },
      imgFullWidth: {
        transform: 'none',
      },
      imgFullHeight: {
        left: 0,
        transform: 'none',
      },
    },
    MuiInputLabel: {
      marginDense: {
        [defaultTheme.breakpoints.down('xs')]: {
          transform: 'translate(0, 10px) scale(1)',
        },
      }
    },
  }
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);
