// internal
import {
  HIDE_MESSAGE_SNACKBAR,
  SHOW_MESSAGE_SNACKBAR,
  SWITCH_TERMS_AND_CONDITIONS_DIALOG_PAGE,
  TOGGLE_REGISTRATION_DIALOG,
} from '../actions/types';

const initialState = {
  registrationDialogOpened: false,
  termsAndConditionsDialogOpened: false,
  messageSnackbar: {
    opened: false,
    content: '',
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_REGISTRATION_DIALOG:
      return {
        ...state,
        registrationDialogOpened: !state.registrationDialogOpened,
      };
    case SWITCH_TERMS_AND_CONDITIONS_DIALOG_PAGE:
      return {
        ...state,
        termsAndConditionsDialogActivePage: payload,
      };
    case SHOW_MESSAGE_SNACKBAR:
      return {
        ...state,
        messageSnackbar: {
          ...state.messageSnackbar,
          opened: true,
          content: action.content,
        },
      };

    case HIDE_MESSAGE_SNACKBAR:
      return {
        ...state,
        messageSnackbar: {
          ...state.messageSnackbar,
          opened: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
