// external
import React from "react";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

// internal
import {
  LANDING_PAGE_ANCHORS,
  LINK_BUTTON_STYLES,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TITLE_BLOCK_STYLES
} from "../constants";

import carLogo from "../../../images/LandingPage/MoreDetail/car_logo.png";
import download from "../../../images/LandingPage/MoreDetail/downloadApp.png";
import pluginAndSet from "../../../images/LandingPage/MoreDetail/pluginAndSet.png";
import ecoOrBoost from "../../../images/LandingPage/MoreDetail/ecoOrBoost.png";
import enjoyBenefits from "../../../images/LandingPage/MoreDetail/enjoyBenefits.png";
import {onNavigate} from "../containers/LandingPage";

const styles = (theme) => ({
  ...TITLE_BLOCK_STYLES(theme),
  whiteBackground: {
    padding: '80px 0',
    backgroundColor: 'white',
    fontFamily: 'Comfortaa',
    fontSize: 20,
  },
  carLogoWrapper: {
    position: 'absolute',
    zIndex: 20,
    right: 0,
    top: -370,
    [theme.breakpoints.down("sm")]: {
      top: 0,
      position: 'inherit',
      justifyContent: 'center',
    },
  },
  carLogo: {
    margin: 10,
    height: 400,
    maxWidth: '100%',
    objectFit: 'contain',
    objectPosition: 'bottom',
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 10,
    }
  },
  snakeWrapper: {
    margin: '30px 0',
    position: 'relative',
  },
  snakeStart: {
    borderTop: `3px solid ${SECONDARY_COLOR}`,
  },
  snakeEnd: {
    borderBottom: `3px solid ${SECONDARY_COLOR}`,
  },
  snakeBody: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'inherit',
    }
  },
  snakeFirstSegment: {
    borderTop: `3px solid ${SECONDARY_COLOR}`,
    height: 250,
  },
  snakeLastSegment: {
    borderBottom: `3px solid ${SECONDARY_COLOR}`,
    height: 675-3,
  },
  leftRotate: {
    height: 250+3,
    borderWidth: '3px 0 3px 3px',
    borderStyle: 'solid',
    borderColor: SECONDARY_COLOR,
    borderRadius: '40px 0 0 40px',
  },
  rightRotate: {
    height: 675,
    borderWidth: '3px 3px 3px 0',
    borderStyle: 'solid',
    borderColor: SECONDARY_COLOR,
    borderRadius: '0 40px 40px 0',
  },
  stepsContainer: {
    top: 84,
    zIndex: 30,
    padding: '0 40px',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: '0 20px',
    }
  },
  step: {
    textAlign: 'center',
    padding: '0 10px',
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  stepImage: {
    maxWidth: 180,
  },
  stepTextWrapper: {
    margin: '10px 0',
    padding: '6px 0',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  stepText: {
    marginBottom: 0,
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  blockWrapper: {
    bottom: -80,
    zIndex: 30,
    padding: '0 40px',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: '0 20px',
    }
  },
  blockTitle: {
    color: PRIMARY_COLOR,
    fontWeight: 'bold',
    fontSize: 22,
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  blockText: {
    fontSize: 20,
    textAlign: 'justify',
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  blockImageWrapper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  blockImage: {
    maxWidth: '100%',
    maxHeight: 350,
    margin: '30px 0 20px 0',
    objectFit: 'contain',
    objectPosition: 'bottom',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
    [theme.breakpoints.only('md')]: {
      margin: '50px 0 20px 0',
      height: 300,
    },
    [theme.breakpoints.only('xl')]: {
      maxHeight: 380,
    },
  },
  straightSnakeWrapper: {
    position: 'absolute',
    height: '100%',
  },
  straightSnake: {
    height: '100%',
    width: 3,
    backgroundColor: SECONDARY_COLOR,
  },
  link: {
    ...LINK_BUTTON_STYLES,
    fontSize: 22,
    [theme.breakpoints.only("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
});

const MoreDetail = ({ classes }) => {
  return (
    <Grid container className={classes.whiteBackground}>
      <Grid container className={classes.titleWrapper}>
        <Grid item xs={11} md={7} className={classes.titleBackground}/>
        <Hidden xsDown>
          <Grid item sm={1} />
        </Hidden>
        <Grid item xs={11} sm={10} md={6} container direction="column" className={classes.titleTextWrapper}>
          <h1 className={classes.title}>The future is smart</h1>
          <p className={classes.paragraph}>
            And getting there is easier than you might think. <br/>
            Just plug in and let us take care of the rest.
          </p>
          <p className={classes.paragraph}>
            Please <wbr/>
            <button
              className={classes.link}
              onClick={() => onNavigate(LANDING_PAGE_ANCHORS.HOW_TO_SING_UP)}
            >
              register your interest
            </button>
            <wbr/> in our trial. If you are selected to take-part then this is how the trial will work....
          </p>
        </Grid>
      </Grid>
      <Grid container alignItems="stretch" justifyContent="center" className={classes.snakeWrapper}>
        <Grid container item sm={12} md={5} className={classes.carLogoWrapper}>
          <Grid container justifyContent="center" item xs={12} sm={10}>
            <img src={carLogo} alt="Car logo" className={classes.carLogo}/>
          </Grid>
        </Grid>
        <Grid container item md={10} className={classes.stepsContainer}>
          <Grid container justifyContent="space-evenly">
            <Grid container item sm={8} md={4} direction="column" alignItems="center" className={classes.step}>
              <img src={download} alt="Download App" className={classes.stepImage}/>
              <Grid container direction="column" className={classes.stepTextWrapper}>
                <p className={classes.blockTitle}>Download the Connected Kerb app</p>
                <p className={classes.stepText}>Use the Connected Kerb app to find a chargepoint.</p>
              </Grid>
            </Grid>
            <Grid container item sm={8} md={4} direction="column" alignItems="center" className={classes.step}>
              <img src={pluginAndSet} alt="Plugin and Set" className={classes.stepImage}/>
              <Grid container direction="column" className={classes.stepTextWrapper}>
                <p className={classes.blockTitle}>Plug in and set your time</p>
                <p className={classes.stepText}>Let the app know what time you need your car to be charged by.</p>
              </Grid>
            </Grid>
            <Grid container item sm={8} md={4} direction="column" alignItems="center" className={classes.step}>
              <img src={ecoOrBoost} alt="Eco or Boost" className={classes.stepImage}/>
              <Grid container direction="column" className={classes.stepTextWrapper}>
                <p className={classes.blockTitle}>Choose either ‘ECO’ or ‘Boost’ charging mode</p>
                <p className={classes.stepText}>
                  Choose how many kWhs or miles you want to charge your car at either the ‘ECO’ or ‘Boost’ rate.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={10} justifyContent="center" className={classes.blockWrapper}>
          <Grid container item sm={8} md={5} className={classes.blockImageWrapper}>
            <img
              src={enjoyBenefits}
              alt="Enjoy the benefits"
              className={classes.blockImage}
            />
          </Grid>
          <Grid container direction="column" justifyContent="center" item sm={8} md={7}>
            <Grid container direction="column" className={classes.stepTextWrapper}>
              <p className={classes.blockTitle}>Enjoy the benefits of smart charging!</p>
              <p className={classes.blockText}>
                If you choose ‘ECO’ charge, Samsung’s algorithm will activate the charger at times when electricity
                is cheaper. Your car will be charged by the time you need it, and you could
                <strong> save up to 40%</strong> by allowing an optimised charge.
              </p>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid container className={classes.snakeEnd}/>
          </Hidden>
        </Grid>
        <Hidden smDown>
          <Grid container item md={1} className={classes.snakeEnd}/>
          <Grid container item md={10} className={classes.snakeBody}>
            <Grid container item md={6} direction="column">
              <Grid container className={classes.leftRotate}/>
              <Grid container className={classes.snakeLastSegment}/>
            </Grid>
            <Grid container item md={6} direction="column">
              <Grid container className={classes.snakeFirstSegment}/>
              <Grid container className={classes.rightRotate}/>
            </Grid>
          </Grid>
          <Grid container item md={1} className={classes.snakeStart}/>
        </Hidden>
        <Hidden mdUp>
          <Grid container justifyContent="center" className={classes.straightSnakeWrapper}>
            <div className={classes.straightSnake}/>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles))(MoreDetail);
