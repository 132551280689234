// external
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// internal
import YesNoRadioGroup from '../YesNoRadioGroup';
import { MODAL_BUTTONS_STYLES, TERMS_AND_CONDITIONS_RADIO_LABELS } from '../../constants';
import { PRIVACY_POLICY_PAGE, TERMS_AND_CONDITIONS_PAGE } from "../../../../actions/types";

const styles = theme => ({
  ...MODAL_BUTTONS_STYLES,
  mainWrapper: {
    padding: '25px 50px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    '& input': {
      fontFamily: 'Comfortaa',
    },
  },
  formControl: {
    marginTop: 30,
  },
  formGroup: {
    justifyContent: 'space-evenly',
  },
  navigationContainer: {
    marginTop: theme.spacing(5),
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  inputLabel: {
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(0, 10px) scale(1)',
    },
  },
  link: {
    margin: 0,
    padding: '0 5px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: 16,
    color: '#0077c8',
    textDecoration: 'underline',
    textTransform: 'none',
    fontFamily: 'Comfortaa',
    '&:hover': {
      color: `#0077c8cc`,
      textDecoration: 'underline',
    },
  },
  label: {
    fontFamily: 'Comfortaa',
  },
});

const INITIAL_STATE = {
  name: '',
  email: '',
  expectedMileage: '',
  happyToBeContacted: true,
  committedToGiveFeedback: true,
  termsAndConditions: true,
  privacyPolicy: true,
};

const ThirdStep = forwardRef(({
  classes, onPrev, getStoreData, updateStoreData, stepName, onSubmit, onSubmitForm, openTermsAndConditions
}, ref) => {
  const [userData, setUserData] = useState(() => {
    const storeData = getStoreData(stepName);

    return storeData ? storeData : INITIAL_STATE;
  });

  const registrationForm = useRef(null);

  const onInputChange = value => {
    setUserData(prevUserData => ({
      ...prevUserData,
      ...value,
    }))
  };

  const onSubmitClick = () => {
    if (!userData['termsAndConditions'] || !userData['privacyPolicy'] || !userData['happyToBeContacted']) return;

    registrationForm.current.submit();
  };

  const saveData = () => {
    updateStoreData(stepName, userData);
  };

  const onFormSubmit = () => {
    saveData();
    onSubmit();
  };

  useImperativeHandle(ref, () => ({
    isValid() {
      return userData['happyToBeContacted'] && userData['termsAndConditions'] && userData['privacyPolicy'] && registrationForm.current.isFormValid();
    },
    onSubmitClick,
    saveData,
  }));

  return (
    <div className={classes.mainWrapper}>
      <ValidatorForm
        ref={registrationForm}
        onSubmit={onFormSubmit}
      >
        <TextValidator
          type="text"
          fullWidth
          required
          label={<span className={classes.label}>Name</span>}
          margin="dense"
          onChange={event => onInputChange({ name: event.target.value })}
          name="name"
          value={userData['name']}
          validators={['required', 'trim', 'maxStringLength:150']}
          errorMessages={['This field is required', 'No blank text', 'No more than 150 symbols']}
        />
        <TextValidator
          type="email"
          fullWidth
          required
          label={<span className={classes.label}>Email address</span>}
          margin="dense"
          onChange={event => onInputChange({ email: event.target.value })}
          name="email"
          value={userData['email']}
          validators={['required', 'isEmail', 'maxStringLength:254']}
          errorMessages={['This field is required', 'Email is not valid', 'No more than 254 symbols']}
        />
        <TextValidator
          type="text"
          fullWidth
          required
          label={<span className={classes.label}>Expected mileage per week</span>}
          margin="dense"
          onChange={event => onInputChange({ expectedMileage: event.target.value })}
          name="expectedMileage"
          value={userData['expectedMileage']}
          validators={['required', 'isNumber', 'minNumber:1', 'maxNumber:10000']}
          errorMessages={['This field is required', 'Only numbers allowed', 'Minimum value is 1', 'Maximum value is 10000']}
        />
        <YesNoRadioGroup
          value={userData['committedToGiveFeedback']}
          groupLabel="Will you commit to giving us feedback about the trial and take part in surveys when required?"
          name="committedToGiveFeedback"
          onSubmit={value => onInputChange({ committedToGiveFeedback: value })}
        />
        <YesNoRadioGroup
          required
          value={userData['happyToBeContacted']}
          groupLabel="Happy to be contacted for more information?"
          name="happyToBeContacted"
          errorText="if we can't contact you you can't take part in the trial"
          onSubmit={value => onInputChange({ happyToBeContacted: value })}
        />
        <YesNoRadioGroup
          required
          value={userData['termsAndConditions']}
          groupLabel={<span>
            Please read
            <Button
              className={classes.link}
              onClick={() => {
                openTermsAndConditions(TERMS_AND_CONDITIONS_PAGE);
              }}
            >
              the terms and conditions
            </Button>
             and select ‘accept’ if you wish to participate in the trial.
          </span>}
          name="termsAndConditions"
          errorText="You need to accept terms and conditions!"
          customLabelMap={TERMS_AND_CONDITIONS_RADIO_LABELS}
          onSubmit={value => onInputChange({ termsAndConditions: value })}
        />
        <YesNoRadioGroup
          required
          value={userData['privacyPolicy']}
          groupLabel={<span>
            We are collecting the data that you provide on this form so that we can contact you with more information
            about the trial, and also assess your suitability for the trial. If you expressed an interest in a
            discounted subscription to an ONTO electric car we will also share your name and email address with ONTO
            to allow them to contact you with more information. We will not use your data for any other purpose.
            For more details please read and accept our&#160;
            <Button
              className={classes.link}
              onClick={() => {
                openTermsAndConditions(PRIVACY_POLICY_PAGE);
              }}
            >
              Privacy policy
            </Button>
          </span>}
          name="privacyPolicy"
          errorText="You need to accept privacy policy!"
          customLabelMap={TERMS_AND_CONDITIONS_RADIO_LABELS}
          onSubmit={value => onInputChange({ privacyPolicy: value })}
        />
      </ValidatorForm>
      <Grid container className={classes.navigationContainer}>
        <Button onClick={onPrev} color="primary" className={classes.button}>
          Previous
        </Button>
        <Button onClick={onSubmitClick} color="primary" className={classes.button}>
          Submit
        </Button>
      </Grid>

    </div>
  );
});

export default compose(withStyles(styles))(ThirdStep);
