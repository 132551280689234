// external
import React from "react";
import { compose } from "redux";
import ReactPlayer from 'react-player';

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// internal
import {onNavigate} from "../containers/LandingPage";
import ontoLogo from "../../../images/LandingPage/onto_logo.png";
import ontoGreenLogo from "../../../images/LandingPage/onto_green_logo.png";

import {
  LANDING_PAGE_MENU_HEIGHT,
  PRIMARY_COLOR,
  LANDING_PAGE_ANCHORS,
  LINK_BUTTON_STYLES,
  INTRO_VIDEO_URL,
} from "../constants";

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
  },
  background: {
    maxWidth: '100%',
    maxHeight: `calc(100vh - ${LANDING_PAGE_MENU_HEIGHT}px - 150px)`,
  },
  textWrapper: {
    backgroundColor: PRIMARY_COLOR,
    color: 'white',
    fontFamily: 'Comfortaa',
    padding: '30px 0',
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    }
  },
  plainText: {
    margin: 0,
    textAlign: 'justify',
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    }
  },
  noCarText: {
    fontSize: 25,
    margin: '0 0 20px 0',
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    }
  },
  moreInfo: {
    fontSize: 17,
    textAlign: 'justify',
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    }
  },
  link: {
    ...LINK_BUTTON_STYLES,
    fontSize: 17,
    padding: '3px 1px',
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  ontoLogo: {
    position: 'relative',
    top: 7,
    left: -1,
    height: 17 + 8,
    padding: 4,
    paddingLeft: 5,
    [theme.breakpoints.down("sm")]: {
      height: 12 + 8,
      top: 5,
    }
  },
  blueTitle: {
    fontFamily: 'Comfortaa',
    fontWeight: 'bold',
    color: PRIMARY_COLOR,
    fontSize: 38,
    margin: 0,
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
    },
  },
  reactPlayer: {
    margin: '30px 0',
    [theme.breakpoints.down("xs")]: {
      margin: 20,
    },
  },
});


const IntroVideo = ({ classes }) => {
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container justifyContent="center">
        <Grid container direction="column" item sm={10} className={classes.reactPlayer}>
          <h6 className={classes.blueTitle}>
            Intro Video
          </h6>
          <ReactPlayer
            url={INTRO_VIDEO_URL}
            width="100%"
            height="auto"
            controls
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" className={classes.textWrapper}>
        <Grid container direction="column" item sm={10}>
            <h2 className={classes.noCarText}>
              Don’t have an electric car? No Problem
            </h2>
            <p className={classes.plainText}>
              You can subscribe to an Electric Car with our
              partner <a href="https://on.to" className={classes.link}>
                <img
                  src={ontoLogo}
                  alt="ONTO"
                  className={classes.ontoLogo}
                  onMouseOver={event => {
                    event.target.src = ontoGreenLogo
                  }}
                  onMouseOut={event => {
                    event.target.src = ontoLogo
                  }}
                />
              </a> and
              get up to 50% off for the ﬁrst two months on selected cars (subject to availability) –
              Flexible, affordable and sustainable driving!
            </p>
            <p className={classes.moreInfo}>
              For more information see &#160;
              <button onClick={() => onNavigate(LANDING_PAGE_ANCHORS.HOW_TO_SING_UP)} className={classes.link}>below</button>
            </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles))(IntroVideo);