// external
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { useTheme, useMediaQuery } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MapIcon from "@material-ui/icons/Map";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { AUTHORITIES_CONFIG } from "../constants";

const styles = theme => ({
  dialogTitle: {
    fontSize: 24,
    color: '#555',
    fontWeight: 'bold',
    margin: '15px auto',
    textAlign: 'center',
  },
  mainContainer: {
    textAlign: 'center',
    fontFamily: 'Comfortaa',
  },
  mapsWrapper: {
    minHeight: '100%',
  },
  map: {
    '&:first-child': {
      marginBottom: 15,
    },
    maxWidth: '100%',
  },
  icon: {
    margin: '5px 10px',
  },
  noMapMessage: {
    fontSize: 24,
    margin: 10,
    [theme.breakpoints.only('sm')]: {
      fontSize: 30,
    },
  },
});


const LocationDialog = ({ classes, onClose, chosenAuthority = null }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={!!chosenAuthority}
      maxWidth="md"
      className={classes.mainContainer}
      aria-labelledby="map-dialog-title"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle id="registration-dialog-title" style={{ padding: 0 }} disableTypography>
        <Grid container justifyContent="space-between" alignItems="center">
          <MapIcon className={classes.icon}/>
          <p className={classes.dialogTitle}>
            {chosenAuthority}
          </p>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon/>
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Grid container direction="column" justifyContent="center" className={classes.mapsWrapper}>
          {!!chosenAuthority && (
            AUTHORITIES_CONFIG[chosenAuthority].maps?.length
              ? AUTHORITIES_CONFIG[chosenAuthority].maps.map((map, index) => (
                <img key={`map-${index}`} src={map} alt={map.name} className={classes.map}/>
              ))
              : <h1 className={classes.noMapMessage}>Locations will be announced <br/> in the next few weeks</h1>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

LocationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  chosenAuthority: PropTypes.string,
};

export default compose(
  withStyles(styles),
)(LocationDialog);
