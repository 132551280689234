// external
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
// internal
import { YES_NO_VALUE_LABEL_MAP } from '../constants';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: '24px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  errorMessage: {
    textAlign: 'center',
    color: '#f44336',
  },
  label: {
    fontFamily: 'Comfortaa',
    lineHeight: '1.5',
    textAlign: 'justify',
  },
});

const values = Object.keys(YES_NO_VALUE_LABEL_MAP);

const getRadioGroupValue = value => {
  return Object.keys(YES_NO_VALUE_LABEL_MAP).find(key => YES_NO_VALUE_LABEL_MAP[key].value === value);
};

const YesNoRadioGroup = ({ classes, groupLabel, name, reverse, customLabelMap, onSubmit, value, required, errorText }) => {
  const onChange = event => {
    const currentValue = event.target.value;

    onSubmit(YES_NO_VALUE_LABEL_MAP[currentValue].value);
  };

  const errorMessage = () => {
    let error = null;

    if (required && ((value && reverse) || (!reverse && !value))) {
      error = (
        <div className={classes.errorMessage}>
          {errorText}
        </div>
      );
    }

    return error;
  };

  const radioGroupValue = getRadioGroupValue(value);

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" required={required} className={classes.formControl} fullWidth>
        <FormLabel component="legend" className={classes.label}>{groupLabel}</FormLabel>
        <RadioGroup
          row
          aria-label={name}
          name={name}
          value={radioGroupValue}
          onChange={event => onChange(event)}
          style={{ justifyContent: 'space-around' }}
        >
          {
            values.map(valueItem => (
              <FormControlLabel
                key={valueItem}
                value={valueItem}
                control={<Radio color="primary" />}
                label={customLabelMap ? customLabelMap[valueItem] : YES_NO_VALUE_LABEL_MAP[valueItem].label}
                classes={{ label: classes.label }}
              />
            ))
          }
        </RadioGroup>
        {errorMessage()}
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(YesNoRadioGroup);
