// external
import React, {useCallback, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

// internal
import LandingPage from "./modules/landing-page/containers/LandingPage";
import RegistrationDialog from "./modules/registration-dialog/containers/RegistrationDialog";
import {
  hideMessageSnackbar,
  postUserRegisterRequest,
  switchTermsAndConditionsDialogPage,
  toggleRegistrationDialog
} from "./actions";
import MessageSnackbar from "./modules/MessageSnackbar";
import TermsAndConditionsDialog from "./modules/terms-and-policy-dialog/TermsAndConditionsDialog";
import {LANDING_PAGE_ANCHORS} from "./modules/landing-page/constants";

const App = () => {
  console.log(window.location.pathname);
  const [blockFocused, setBlockFocused] = useState(window.location.hash === '#video' ? LANDING_PAGE_ANCHORS.INTRO_VIDEO : null);
  const dispatch = useDispatch();
  const registrationDialogOpened = useSelector(
    (state) => state.registrationDialogOpened
  );
  const termsAndConditionsDialogActivePage = useSelector(
    (state) => state.termsAndConditionsDialogActivePage
  );
  const messageSnackbar = useSelector(
    (state) => state.messageSnackbar
  );

  const onSubmit = useCallback((formData) => {
    const {
      step1: authority,
      step2: {
        mostlyUsedLocations,
        haveElectricCar,
        makeOfCar,
        chargesPerWeek,
        whenExpectToCharge,
        interestedInRent,
        committedToCharge,
        whyInterestedInTrial,
      },
      step3: {
        name,
        email,
        expectedMileage,
      },
    } = formData;

    const userData = {
      authority,
      mostlyUsedLocations,
      haveElectricCar,
      makeOfCar,
      chargesPerWeek,
      whenExpectToCharge,
      interestedInRent,
      committedToCharge,
      whyInterestedInTrial,
      name,
      email,
      expectedMileage,
    };

    return dispatch(postUserRegisterRequest(userData))
  }, [dispatch])

  return (
    <>
      <LandingPage blockFocused={blockFocused} setBlockFocused={setBlockFocused} />
      <RegistrationDialog
        setBlockFocused={setBlockFocused}
        isOpened={registrationDialogOpened}
        openTermsAndConditions={page => dispatch(switchTermsAndConditionsDialogPage(page))}
        onClose={() => dispatch(toggleRegistrationDialog())}
        onSubmit={onSubmit}
      />
      <TermsAndConditionsDialog
        activePage={termsAndConditionsDialogActivePage}
        onClose={()=> dispatch(switchTermsAndConditionsDialogPage(null))}
      />
      <MessageSnackbar
        open={messageSnackbar.opened}
        message={messageSnackbar.content}
        onClose={()=> dispatch(hideMessageSnackbar())}
      />
    </>
  );
};

export default App;
