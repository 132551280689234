// external
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import { compose } from 'redux';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import Slider from "@material-ui/core/Slider";

// internal
import YesNoRadioGroup from "../YesNoRadioGroup";
import { MODAL_BUTTONS_STYLES } from "../../constants";
import { AUTHORITIES_CONFIG } from "../../../landing-page/constants";

const styles = theme => ({
  ...MODAL_BUTTONS_STYLES,
  formContainer: {
    padding: '0px 50px 25px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    '& input': {
      fontFamily: 'Comfortaa',
    },
  },
  navigationContainer: {
    marginTop: theme.spacing(5),
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  formControl: {
    width: '100%',
  },
  sliderFormControl: {
    maxWidth: 600,
    minWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    fontFamily: 'Comfortaa',
  },
  largeInputLabel: {
    marginBottom: 0,
    fontSize: '1rem',
    fontFamily: 'Comfortaa',
    textAlign: 'justify',
    transition: '0.4s ease-out',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  label: {
    fontFamily: 'Comfortaa',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const INITIAL_STATE = {
  mostlyUsedLocations: [],
  haveElectricCar: false,
  makeOfCar: '',
  chargesPerWeek: 1,
  whenExpectToCharge: '',
  interestedInRent: false,
  committedToCharge: false,
  whyInterestedInTrial: '',
  overTwentyFive: true,
  resident: true,
  faultInsurance: false,
};

const SecondStep = forwardRef(({
     classes, onPrev, getStoreData, updateStoreData, stepName, onSubmit, localAuthority,
   }, ref) => {
  const [userData, setUserData] = useState(() => {
    const storeData = getStoreData(stepName);

    return storeData ? storeData : INITIAL_STATE;
  });
  const [isFieldFocused, setIsFieldFocused] = useState({});

  const registrationForm = useRef(null);

  useImperativeHandle(ref, () => ({
    isValid() {
      return registrationForm.current.isFormValid();
    },
    onSubmitClick,
    saveData,
  }));

  const saveData = () => {
    updateStoreData(stepName, userData);
  };

  const onFormSubmit = () => {
    saveData();
    onSubmit();
  };

  const onInputChange = value => {
    setUserData(prevUserData => ({
      ...prevUserData,
      ...value,
    }))
  };

  const handleFocus = key => {
    setIsFieldFocused(prevState => ({
      ...prevState,
      [key]: true,
    }))
  };

  const handleBlur = key => {
    setIsFieldFocused(prevState => ({
      ...prevState,
      [key]: false,
    }))
  };

  const getLabelStyles = key => {
    return isFieldFocused[key] ? {
      color: '#0077c8',
    } : {};
  }

  const onSubmitClick = () => {
    registrationForm.current.submit();
  };

  const valuetext = (value) => {
    return `${value}`;
  }

  return (
    <div className={classes.formContainer}>
      <ValidatorForm
        ref={registrationForm}
        onSubmit={onFormSubmit}
      >
        {AUTHORITIES_CONFIG[localAuthority].locations.length ? (
            <FormControl className={classes.formControl}>
              <p
                className={classes.largeInputLabel}
                style={getLabelStyles('mostlyUsedLocations')}
              >
                Select the street/location which you will use the most (select up to 10 locations)
              </p>
              <Select
                multiple
                value={userData['mostlyUsedLocations']}
                onChange={event => {
                  onInputChange(event.target.value.length <= 10 ? { mostlyUsedLocations: event.target.value }: {})
                }}
                onFocus={() => {
                  handleFocus('mostlyUsedLocations');
                }}
                onBlur={() => {
                  handleBlur('mostlyUsedLocations');
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {AUTHORITIES_CONFIG[localAuthority].locations.map(location => (
                  <MenuItem key={location} value={location} classes={{ root: classes.label }}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <p className={classes.largeInputLabel}>
              Exact chargepoint locations will be confirmed in the next few weeks.
              We will email you as soon as the locations are confirmed
            </p>
          )
        }
        <YesNoRadioGroup
          value={userData['haveElectricCar']}
          groupLabel="Do you have an electric car?"
          name="haveElectricCar"
          onSubmit={value => onInputChange({ haveElectricCar: value })}
        />
        {userData['haveElectricCar'] ? (
          <React.Fragment>
            <TextValidator
            type="text"
            fullWidth
            label={<span className={classes.label}>What make of car?</span>}
            margin="dense"
            onChange={event => { onInputChange({ makeOfCar: event.target.value }); }}
            name="makeOfCar"
            value={userData['makeOfCar']}
            validators={['maxStringLength:50']}
            errorMessages={['No more than 50 symbols']}
            >
              What make of car?
            </TextValidator>
            <FormControl className={classes.sliderFormControl}>
              <InputLabel id="charges-per-week">
                How often do you charge your car? (times per week)
              </InputLabel>
              <br/><br/>
              <Slider
                marks
                step={1}
                min={1}
                max={7}
                valueLabelDisplay="auto"
                area-labelledby="charges-per-week"
                getAriaValueText={valuetext}
                value={userData['chargesPerWeek']}
                onChange={(event, newValue) => {
                  onInputChange({ chargesPerWeek: newValue });
                }}
              />
            </FormControl>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <YesNoRadioGroup
              value={userData['interestedInRent']}
              groupLabel="Are you interested in subscribing to an electric car with our partner ONTO at special rate for at least 2 months?"
              name="interestedInRent"
              onSubmit={value => onInputChange({ interestedInRent: value })}
            />
            {userData["interestedInRent"] && (
              <React.Fragment>
                <YesNoRadioGroup
                  required
                  value={userData['overTwentyFive']}
                  groupLabel="Are you over 25 years old?"
                  name="committedToCharge"
                  onSubmit={value => onInputChange({ overTwentyFive: value })}
                  errorText="Sorry but you are not eligible for an Onto car subscription."
                />
                <YesNoRadioGroup
                  required
                  value={userData['resident']}
                  groupLabel="Are you resident in the UK?"
                  name="committedToCharge"
                  onSubmit={value => onInputChange({ resident: value })}
                  errorText="Sorry but you are not eligible for an Onto car subscription."
                />
                <YesNoRadioGroup
                  required
                  reverse
                  value={userData['faultInsurance']}
                  groupLabel="Have you had any fault car insurance claims in the last 3 years?"
                  name="committedToCharge"
                  onSubmit={value => onInputChange({ faultInsurance: value })}
                  errorText="Sorry but you are not eligible for an Onto car subscription."
                />
              </React.Fragment>
            )}
            <YesNoRadioGroup
              value={userData['committedToCharge']}
              groupLabel="Will you commit to using the trial chargepoints to charge your new car at least twice a month?"
              name="committedToCharge"
              onSubmit={value => onInputChange({ committedToCharge: value })}
              />
            <FormControl className={classes.formControl}>
              <p
                className={classes.largeInputLabel}
                style={getLabelStyles('whyInterestedInTrial')}
              >
                We are looking for Electric Vehicle advocates for the trial.
                Please tell us briefly why you are interested in signing up for the trial
              </p>
              <TextValidator
                type="text"
                fullWidth
                margin="dense"
                onChange={event => { onInputChange({ whyInterestedInTrial: event.target.value }); }}
                name="WhyInterestedInTrial"
                value={userData['whyInterestedInTrial']}
                validators={['maxStringLength:300']}
                errorMessages={['No more than 300 symbols']}
                onFocus={() => {
                  handleFocus('whyInterestedInTrial');
                }}
                onBlur={() => {
                  handleBlur('whyInterestedInTrial');
                }}
              />
            </FormControl>
          </React.Fragment>
        )}
        <FormControl className={classes.formControl}>
          <p
            className={classes.largeInputLabel}
            style={getLabelStyles('whenExpectToCharge')}
          >
            At what times of day do you expect to use the chargepoint?
            (e.g. overnight; while I’m at work; when I’m in town centre for the day)
          </p>
          <TextValidator
            type="text"
            fullWidth
            margin="dense"
            onChange={event => { onInputChange({ whenExpectToCharge: event.target.value }); }}
            name="whenExpectToCharge"
            value={userData['whenExpectToCharge']}
            validators={['maxStringLength:100']}
            errorMessages={['No more than 100 symbols']}
            onFocus={() => {
              handleFocus('whenExpectToCharge');
            }}
            onBlur={() => {
              handleBlur('whenExpectToCharge');
            }}
          />
        </FormControl>
      </ValidatorForm>
      <Grid container item  className={classes.navigationContainer}>
        <Button onClick={onPrev} color="primary" className={classes.button}>
          Previous
        </Button>
        <Button onClick={onSubmitClick} color="primary" className={classes.button}>
          Next
        </Button>
      </Grid>
    </div>
  );
});

export default compose(withStyles(styles))(SecondStep);
