// external
import React from 'react';
import { without } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';

const styles = theme => ({
  root: {
    width: '100%',
  },
  stepperRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  stepLabel: {
    fontFamily: 'Comfortaa',
  },
  stepLabelContainerHidden: {
    display: 'none',
  },
});

const getStepIsDisabled = (currStep, steps, isLastStep) => {
  if (!isLastStep) return !currStep.completed;

  const lastStepIsAvailable = without(steps, currStep).every(step => step.completed);

  return !lastStepIsAvailable;
};

const StepsProgress = ({ classes, steps, activeStep, showStepLabel, handleNavigateToStep }) => (
  <div className={classes.root}>
    <Stepper activeStep={activeStep - 1} classes={{ root: classes.stepperRoot }} alternativeLabel>
      {steps.map((step, index) => (
        <Step key={step.step} disabled={getStepIsDisabled(step, steps, index === steps.length - 1)}>
          <StepButton
            onClick={handleNavigateToStep(step.step)}
            completed={step.completed}
          >
            <StepLabel
              classes={{ 
                labelContainer: showStepLabel ? null : classes.stepLabelContainerHidden,
                label: classes.stepLabel
              }}
            >
              {step.label}
            </StepLabel>
          </StepButton>
        </Step>
      ))
      }
    </Stepper>
  </div>
);

export default withStyles(styles)(StepsProgress);
