// external
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// internal
import {MODAL_BUTTONS_STYLES} from "../../constants";

const styles = theme => ({
  ...MODAL_BUTTONS_STYLES,
  root: {
    width: '100%',
    padding: '0px 50px 25px',
    fontFamily: 'Comfortaa',
    fontSize: 18,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  title: {
    color: '#0077c8',
  },
  formContainer: {
    width: '100%',
    marginBottom: '25px',
  },
});

const FourthStep = ({ classes, onClose }) => {
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>
        Thanks for registering. <br/>We have sent you an email with more details on how to use the charge points
      </h3>
      <Grid container justifyContent="center" className={classes.navigationContainer}>
        <Button onClick={onClose} color="primary" className={classes.button}>
          Close
        </Button>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(FourthStep);
