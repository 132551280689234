// external
import React from "react";
import {compose} from "redux";

import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

// internal
import LocationDialog from "./LocationDialog";
import { AUTHORITIES, AUTHORITY_LOGO_STYLES, PRIMARY_COLOR, TITLE_BLOCK_STYLES } from "../constants";
import map from "../../../images/LandingPage/Locations/uk-map-chargepoints.png";

import eastLothianCouncil from '../../../images/Authorities/east_lothian_blue.png';
import glasgowCouncil from '../../../images/Authorities/glasgow.png';
import shropshireCouncil from '../../../images/Authorities/shropshire.png';
import hackneyCouncil from '../../../images/Authorities/hackney_white.png';

const styles = theme => ({
  ...TITLE_BLOCK_STYLES(theme),
  marginTop: {
    height: 40,
  },
  contentContainer: {
    backgroundColor: 'white',
    fontFamily: 'Comfortaa',
    paddingBottom: 40,
    minHeight: 750,
  },
  plainText:{
    color: 'black',
    fontSize: 20,
    paddingTop: 30,
    margin:0
  },
  whiteTitle:{
    color: 'white',
    fontSize: 25,
    fontWeight: 'bold',
    paddingTop: 20,
    margin:0
  },
  planeTextWhite:{
    color: 'white',
    fontSize: 20,
    margin:0,
    paddingTop: 25,
  },
  mapWrapper: {
    position: 'absolute',
    right: 0,
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      position: 'inherit',
    },
  },
  mapHeightWrapper: {
    maxWidth: 430,
    maxHeight: 600,
    position: 'relative',
  },
  locationOfChargePoints: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    position: 'absolute',
    backgroundImage: `url(${map})`,
    paddingBottom: `${(7/5)*100}%`,
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      position: 'inherit',
    },
    '& button':{
      backgroundColor: 'transparent',
      border: 0,
      padding: 0,
      cursor: 'pointer',
    },
  },
  mapTooltip: {
    position: 'absolute',
    width: '45%',
    textAlign: 'center',
    top: 0,
    right: 0,
    color: 'white',
    borderRadius: 30,
    padding: 10,
    backgroundColor: PRIMARY_COLOR,
    '@media (max-width: 500px)': {
      fontSize: 10,
      width: '50%',
    },
    '@media (max-width: 370px)': {
      fontSize: 8,
      width: '40%',
    },
  },
  eastLothianButton: {
    ...AUTHORITY_LOGO_STYLES,
    width: '22%',
    height: '8.5%',
    top: '29%',
    left: '48%',
    backgroundImage: `url(${eastLothianCouncil})`,
  },
  glasgowButton: {
    ...AUTHORITY_LOGO_STYLES,
    width: '12.2%',
    height: '14%',
    top: '50.2%',
    left: '24.5%',
    backgroundImage: `url(${glasgowCouncil})`,
  },
  shropshireButton: {
    ...AUTHORITY_LOGO_STYLES,
    width: '24.5%',
    height: '7.5%',
    top: '69.5%',
    left: '18%',
    backgroundImage: `url(${shropshireCouncil})`,
  },
  hackneyButton: {
    ...AUTHORITY_LOGO_STYLES,
    width: '28.5%',
    height: '7%',
    top: '79%',
    left: '72%',
    backgroundImage: `url(${hackneyCouncil})`,
  },
  eastLothianChargePointButton: {
    position: 'absolute',
    borderRadius: '50%',
    width: '9.8%',
    height: '7%',
    top: '33%',
    left: '32%',
  },
  glasgowChargePointButton: {
    position: 'absolute',
    borderRadius: '50%',
    width: '9.8%',
    height: '7%',
    top: '44.3%',
    left: '32.8%',
  },
  shropshireChargePointButton: {
    position: 'absolute',
    borderRadius: '50%',
    width: '9.8%',
    height: '7%',
    top: '69.3%',
    left: '41%',
  },
  hackneyChargePointButton: {
    position: 'absolute',
    borderRadius: '50%',
    width: '9.8%',
    height: '7%',
    top: '78.8%',
    left: '64%',
  },
  authorityButtonHover: {
    '@media (min-width: 500px)': {
      border: '5px solid rgba(13,68,7,0.3) !important',
      transition: 'all 0.01s ease',
      backgroundSize: '100% 100%',
    },
  },
});

const Locations = ({classes}) => {
  const [chosenAuthority, setChosenAuthority] = React.useState(null);
  const [focusedAuthority, setFocusedAuthority] = React.useState(null);

  const authorityClasses = {
    [AUTHORITIES.EAST_LOTHIAN]: {
      button: classes.eastLothianButton,
      chargePointButton: classes.eastLothianChargePointButton,
    },
    [AUTHORITIES.GLASGOW]: {
      button: classes.glasgowButton,
      chargePointButton: classes.glasgowChargePointButton,
    },
    [AUTHORITIES.SHROPSHIRE]: {
      button: classes.shropshireButton,
      chargePointButton: classes.shropshireChargePointButton,
    },
    [AUTHORITIES.HACKNEY]: {
      button: classes.hackneyButton,
      chargePointButton: classes.hackneyChargePointButton,
    },
  }

  return (
    <Grid container direction="column" className={classes.contentContainer}>
      <Grid container className={classes.marginTop} />
      <Grid container>
        <Grid container className={classes.titleWrapper}>
          <Grid item xs={11} md={6} className={classes.titleBackground}/>
          <Hidden xsDown>
            <Grid item sm={1} />
          </Hidden>
          <Grid item xs={11} sm={10} md={5} container direction="column" className={classes.titleTextWrapper}>
            <h1 className={classes.title}>Locations</h1>
            <p className={classes.paragraph}>
              Agile streets is seeking participants across these four local authority areas to help us collect
              data and gather feedback on electric vehicle (EV) drivers' charging behaviors and how smart
              charging can reduce the cost of charging EVs.
            </p>
          </Grid>
        </Grid>
        <Grid container item md={6} justifyContent="center" className={classes.mapWrapper}>
          <Grid container className={classes.mapHeightWrapper}>
            <div
              className={classes.locationOfChargePoints}
            >
              <p className={classes.mapTooltip}>
                Click on a Local Authority Logo to see exact locations of the charge points.
              </p>
              {Object.values(AUTHORITIES).map(authority => (
                <React.Fragment key={authority}>
                  <button
                    className={`${authorityClasses[authority].button} ${
                      focusedAuthority === authority ? classes.authorityButtonHover : ''
                    }`}
                    onClick={() => setChosenAuthority(authority)}
                    onMouseOver={() => !focusedAuthority && setFocusedAuthority(authority)}
                    onMouseOut={() => setFocusedAuthority(null)}
                  />
                  <button
                    className={`${authorityClasses[authority].chargePointButton} ${
                      focusedAuthority === authority ? classes.authorityButtonHover : ''
                    }`}
                    onClick={() => setChosenAuthority(authority)}
                    onMouseOver={() => !focusedAuthority && setFocusedAuthority(authority)}
                    onMouseOut={() => setFocusedAuthority(null)}
                  />
                </React.Fragment>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <LocationDialog
        chosenAuthority={chosenAuthority}
        onClose={() => setChosenAuthority(null)}
      />
    </Grid>
  );
};

export default compose(withStyles(styles))(Locations);