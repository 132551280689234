// external
import axios from 'axios';

// internal
import {
  HIDE_MESSAGE_SNACKBAR,
  SHOW_MESSAGE_SNACKBAR,
  SWITCH_TERMS_AND_CONDITIONS_DIALOG_PAGE,
  TOGGLE_REGISTRATION_DIALOG,
} from './types';
import {BASE_URL} from "./httpConfig";
import formatErrorMessageFromError from "../utils/errorHandler";

export const toggleRegistrationDialog = () => {
  return {
    type: TOGGLE_REGISTRATION_DIALOG,
  };
};

export const switchTermsAndConditionsDialogPage = page => {
  return {
    type: SWITCH_TERMS_AND_CONDITIONS_DIALOG_PAGE,
    payload: page,
  };
};

export const hideMessageSnackbar = () => {
  return {
    type: HIDE_MESSAGE_SNACKBAR,
  };
}

export function showMessageSnackbar(content, delay = 3000) {
  return (dispatch) => {
    dispatch({
      type: SHOW_MESSAGE_SNACKBAR,
      content,
    });
    setTimeout(() => { dispatch(hideMessageSnackbar()); }, delay);
  };
}

export function postUserRegisterRequest(userData) {
  return dispatch => new Promise((resolve) => {
    axios.post(
      `${BASE_URL}/users/sign-up/`,
      userData,
    )
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        dispatch(showMessageSnackbar(
          error.response?.status === 406 ? 'User with provided email already exists!' : formatErrorMessageFromError(error))
        );
        console.log(error); // eslint-disable-line no-console
        resolve(false);
      });
  });
}
