import React from "react";
import { compose } from "redux";

import { useTheme, useMediaQuery } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import DialogTitle from "@material-ui/core/DialogTitle";
import SubjectIcon from "@material-ui/icons/Subject";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import {
  TERMS_AND_CONDITIONS_PAGE,
} from "../../actions/types";
import { PRIMARY_COLOR } from "../landing-page/constants";
import { TERMS_AND_CONDITIONS, PRIVACY_POLICY } from "./constants";

const styles = theme => ({
  dialogTitle: {
    color: "#555",
    fontWeight: "bold",
    margin: "15px auto",
    fontFamily: "Comfortaa",
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '4vw',
    },
  },
  icon: {
    margin: "5px 10px",
  },
  dialogContent: {
    padding: 0,
    fontFamily: "Comfortaa",
    textAlign: "justify",
  },
  contentWrapper: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px 0 25px',
    },
  },
  list: {
    padding: 0,
    fontSize: 15,
  },
  listItem: {
    "&::marker": {
      color: PRIMARY_COLOR,
      fontWeight: "bold",
      fontSize: "1.2em",
    },
  },
  title: {
    fontWeight: "normal",
    color: PRIMARY_COLOR
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    borderCollapse: "collapse",
    width: '100%',
  },
  tableData: {
    border: "1px solid black",
    borderCollapse: "collapse",
    justify: "center",
    padding: 5,
  },
  policyWrapper: {
    width: '100%',
  },
  smallList: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 0 0 15px',
    },
  },
});

const TermsAndConditionsDialog = ({ classes, activePage, onClose }) => {
  const renderTermsAndConditions = () => (
    <ul className={classes.list}>
      {TERMS_AND_CONDITIONS.map((article, i) => (
        <li key={`article-${i}`} className={classes.listItem}>
          <h2 className={classes.title}>{article.title}</h2>
          {article.items &&
          article.items.map((item, j) => {
            switch (item.type) {
              case "ol":
                return (
                  <ol className={classes.smallList} key={`term-${i}-${j}`}>
                    {item.items &&
                    item.items.map((listItem, k) => (
                      <li key={`term-${i}-${j}-${k}`}>{listItem}</li>
                    ))}
                  </ol>
                );
              case "ul":
                return (
                  <ul className={classes.smallList} key={`term-${i}-${j}`}>
                    {item.items &&
                    item.items.map((listItem, k) => (
                      <li key={`term-${i}-${j}-${k}`}>{listItem}</li>
                    ))}
                  </ul>
                );
              default:
                return <p key={`term-${i}-${j}`}>{item.content}</p>;
            }
          })}
        </li>
      ))}
    </ul>
  );

  const renderPrivacyPolicy = () => (
    <div className={classes.policyWrapper}>
      {PRIVACY_POLICY.map((item, i) => {
        switch (item.type) {
          case "h3":
            return <h3 key={`item-${i}`}>{item.content}</h3>;
          case "ul":
            return (
              <ul key={`item-${i}`} className={classes.smallList}>
                {item.items &&
                item.items.map((listItem, index) => (
                  <li key={`item-${i}-${index}`}>{listItem}</li>
                ))}
              </ul>
            );
          case "table":
            return (
              <div key={`item-${i}`} className={classes.tableWrapper}>
                <table className={classes.table}>
                  <tbody>
                  {item.items &&
                  item.items.map((tableRow, rowNum) => (
                    <tr key={`row-${rowNum}`}>
                      {tableRow.data &&
                      tableRow.data.map((td, tdIndex) => (
                        <td key={`tdItem-${rowNum}-${tdIndex}`} className={classes.tableData}>{td}</td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            );
          case "div":
            return <div key={`item-${i}`}>{item.content}</div>
          default:
            return <p key={`item-${i}`}>{item.content}</p>;
        }
      })}
    </div>
  );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={!!activePage}
      aria-labelledby="terms-dialog-title"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle
        id="registration-dialog-title"
        style={{ padding: 0 }}
        disableTypography
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <SubjectIcon className={classes.icon} />
          <h2 className={classes.dialogTitle}>
            {activePage === TERMS_AND_CONDITIONS_PAGE ? (
              <strong>Terms and Conditions</strong>
            ) : (
              <strong>
                Agile Streets Pilot Project
                {theme.breakpoints.down('xs') && <br />}
                Privacy Policy (“Privacy Policy”)
              </strong>
            )}
          </h2>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Grid>
        <Divider />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container justifyContent="center">
          <Grid item sm={10} container direction="column" className={classes.contentWrapper}>
            {activePage === TERMS_AND_CONDITIONS_PAGE
              ? renderTermsAndConditions()
              : renderPrivacyPolicy()}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default compose(
  withStyles(styles)
)(TermsAndConditionsDialog);
