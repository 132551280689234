// external
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Element, scroller } from "react-scroll";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ExpandMore from "@material-ui/icons/ExpandMore";

// internal
import LandingTopBar from "../components/LandingTopBar";
import Summary from "../components/Summary";
import MoreDetail from "../components/MoreDetail";
import {
  LANDING_PAGE_ANCHORS,
  LANDING_PAGE_MENU_HEIGHT,
  LANDING_PAGE_NAVIGATION_BUTTONS,
  MIN_SCROLL_PIXELS_COUNT_TO_SHOW_SCROLL_BUTTON,
  SCROLLER_CONFIG,
  SCROLLER_DEFAULT_OFFSET,
} from "../constants";
import { toggleRegistrationDialog } from "../../../actions";
import HowToSignUp from "../components/HowToSignUp";
import Locations from "../components/Locations";
import FAQ from "../components/FAQ";
import IntroVideo from "../components/IntroVideo";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh", // need vh for IE compatibility
  },
  headerRoot: {
    height: LANDING_PAGE_MENU_HEIGHT,
    padding: "10px 140px",
    borderBottom: "1px solid #e8e8e8",
    [theme.breakpoints.only("sm")]: {
      paddingLeft: "calc(50% - 180px)",
      paddingRight: "20%",
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.only("xs")]: {
      padding: "10px 10%",
    },
  },
  headerLogo: {
    width: 85,
    height: 30,
    borderRadius: 0,
  },
  infoPagesContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  infoSectionWrapper: {
    marginBottom: 10,
  },
  infoPageScrollWrapper: {
    width: "100%",
  },
  scrollToTopButton: {
    visibility: "hidden",
    position: "fixed",
    right: "2%",
    bottom: 60,
    backgroundColor: '#2f8cad',
    transform: "rotate(180deg)",
    marginTop: 0,
    height: 40,
    width: 40,
    fontWeight: 700,
    zIndex: 100,
    padding: 0, // for proper centering in IE
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.down("sm")]: {
      right: 10,
    },
    [theme.breakpoints.down("xs")]: {
      right: 5,
    },
  },
});

export const onNavigate = (pageName, offset = SCROLLER_DEFAULT_OFFSET) => {
  scroller.scrollTo(pageName, {
    ...SCROLLER_CONFIG,
    offset,
  });
};

const scrollToTop = () => {
  onNavigate(LANDING_PAGE_ANCHORS.TOP_OF_PAGE);
};

const LandingPage = ({ classes, blockFocused, setBlockFocused }) => {
  const scrollButtonRef = useRef(null);
  const dispatch = useDispatch();

  const windowScrollHandler = useCallback(() => {
    const scrollY = window.pageYOffset;
    scrollButtonRef.current.style.visibility =
      scrollY > MIN_SCROLL_PIXELS_COUNT_TO_SHOW_SCROLL_BUTTON
        ? "visible"
        : "hidden";
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", windowScrollHandler);

    return () => {
      window.removeEventListener("scroll", windowScrollHandler);
    };
  }, [windowScrollHandler]);

  if (blockFocused) {
    setTimeout(() => {
      onNavigate(blockFocused);
      setBlockFocused(null);
    }, 100);
  }

  return (
    <Grid name="TopOfPage" container className={classes.root} alignContent="flex-start">
      <Grid item container className={classes.headerRoot} alignItems="flex-end">
        <LandingTopBar
          navigationButtons={LANDING_PAGE_NAVIGATION_BUTTONS}
          onNavigateButtonClick={onNavigate}
          onRegisterButtonClick={() => dispatch(toggleRegistrationDialog())}
        />
      </Grid>
      <Grid container direction="column">
        <Summary/>
        <Element name={LANDING_PAGE_ANCHORS.INTRO_VIDEO} className={classes.infoPageScrollWrapper}>
          <IntroVideo/>
        </Element>
        <Element name={LANDING_PAGE_ANCHORS.MORE_DETAIL} className={classes.infoPageScrollWrapper}>
          <MoreDetail />
        </Element>
        <Element name={LANDING_PAGE_ANCHORS.LOCATIONS} id="locations" className={classes.infoPageScrollWrapper}>
          <Locations/>
        </Element>
        <Element name={LANDING_PAGE_ANCHORS.HOW_TO_SING_UP} id="howToSignUp" className={classes.infoPageScrollWrapper}>
          <HowToSignUp/>
        </Element>
        <Element name={LANDING_PAGE_ANCHORS.FAQ} className={classes.infoPageScrollWrapper}>
          <FAQ/>
        </Element>
      </Grid>
      <IconButton ref={scrollButtonRef} className={classes.scrollToTopButton} onClick={scrollToTop}>
        <ExpandMore style={{ color: "#fff" }} />
      </IconButton>
    </Grid>
  );
};

export default compose(withStyles(styles))(LandingPage);