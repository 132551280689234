// external
import React from "react";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// internal
import logo from "../../../images/LandingPage/logo.png";
import homeBg from "../../../images/LandingPage/home_image.png";

import {
  LANDING_PAGE_MENU_HEIGHT,
  PRIMARY_COLOR,
} from "../constants";

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
  },
  mainWrapper: {
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${LANDING_PAGE_MENU_HEIGHT}px)`,
    }
  },
  contentWrapper: {
    position: 'relative',
  },
  titleWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'inherit',
    },
  },
  title: {
    color: PRIMARY_COLOR,
    fontSize: 70,
    fontFamily: 'Comfortaa',
    fontWeight: 'normal',
    lineHeight: 1.2,
    margin: '30px 0',
    [theme.breakpoints.only('md')]: {
      fontSize: 60,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 46,
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 35,
    },
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '80px',
    margin: '30px 0',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    }
  },
  background: {
    maxWidth: '100%',
    maxHeight: `calc(100vh - ${LANDING_PAGE_MENU_HEIGHT}px - 150px)`,
  },
  textWrapper: {
    backgroundColor: PRIMARY_COLOR,
    color: 'white',
    fontFamily: 'Comfortaa',
    padding: '30px 0',
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    }
  },
  plainText: {
    margin: 0,
    textAlign: 'justify',
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    }
  },
});


const Summary = ({ classes }) => {
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container justifyContent="center" className={classes.mainWrapper}>
        <Grid container item xs={12} sm={10} justifyContent="flex-end" className={classes.contentWrapper}>
          <Grid container item xs={12} md={6} className={classes.titleWrapper}>
            <h1 className={classes.title}>Switch to<br/> smart<br/> charging</h1>
          </Grid>
          <Grid container justifyContent="center" item xs={12} md={6}>
            <img src={logo} className={classes.logo} alt="Agile Streets" />
          </Grid>
          <Grid container justifyContent="center">
            <img src={homeBg} alt="background" className={classes.background}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        className={classes.textWrapper}
      >
        <Grid container item sm={10}>
          <Grid container>
            <p className={classes.plainText}>
              We’re looking for electric car drivers in <i>East Lothian, Glasgow,
              Hackney and Shropshire</i> to try out a new way of charging on streets
              and in car parks. We’ll be installing new chargepoints in your
              area by late October 2021, which will use smart metering technology to
              make charging cheaper for people who don’t have driveways.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles))(Summary);