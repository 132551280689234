// external
import React from 'react';
import { compose } from 'redux';
import { useDispatch } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";

// internal
import { LINK_BUTTON_STYLES, PRIMARY_COLOR } from "../constants";
import enjoyBenefits from "../../../images/LandingPage/MoreDetail/enjoyBenefits.png";
import { toggleRegistrationDialog } from "../../../actions";

const styles = theme => ({
  whiteBackground: {
    padding: '60px 0',
    backgroundColor: 'white',
    color: 'black',
    fontFamily: 'Comfortaa',
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  blueBackground: {
    backgroundColor: PRIMARY_COLOR,
    color: 'white',
    fontFamily: 'Comfortaa',
    [theme.breakpoints.down("xs")]: {
      padding: '0 20px',
    },
  },
  buttonRegisterWrapper: {
    margin: '30px 0 40px 0'
  },
  buttonRegister: {
    color: PRIMARY_COLOR,
    fontFamily: 'Comfortaa',
    width: 240,
    height: 75,
    borderRadius: 5,
    fontSize: 22,
    backgroundColor: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#c8f1fa',
    },
    "&:active": {
      lineHeight: 1,
      letterSpacing: '0.029em',
    },
    [theme.breakpoints.only("sm")]: {
      height: 65,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
      width: '100%',
      height: 55,
    },
  },
  carLogo: {
    maxWidth: 500,
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      padding: '0 20px',
    },
  },
  benefitsHeaderWrapper: {
    justify: "flex-end",
    padding: '20px 0 20px 20px',
    [theme.breakpoints.only('sm')]: {
      padding: '20px 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  plainText: {
    fontSize: 20,
    paddingTop: '30px',
    margin: 0,
    textAlign: 'justify',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  blueTitle: {
    color: PRIMARY_COLOR,
    fontSize: 28,
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      paddingTop: '30px',
    },
  },
  title: {
    color: 'white',
    fontSize: 25,
    fontWeight: "bold",
    paddingTop: '40px',
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 22,
    paddingTop: 30,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
  link: {
    ...LINK_BUTTON_STYLES,
    fontSize: 22,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
  blueLink: {
    color: '#0077c8',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: `#0077c8cc`,
    },
  },
});


const HowToSignUp = ({classes}) => {
  const dispatch = useDispatch();

  return (
    <Grid container id="keenToTakePart">
      <Grid container justifyContent="center" className={classes.whiteBackground}>
        <Grid container item md={10}>
          <Grid container item md={6} justifyContent="center" alignItems="center">
            <img src={enjoyBenefits} className={classes.carLogo} alt="Car Charging" />
          </Grid>
          <Grid item md={6} container justifyContent="center">
            <Grid item sm={10} md={12} container direction="column" className={classes.benefitsHeaderWrapper}>
              <h6 className={classes.blueTitle}>
                Keen to take part?
              </h6>
              <p className={classes.plainText}>
                We are looking for drivers who will be able to use the chargepoints between
                late October
                2021
                and May 2022.
              </p>
              <p className={classes.plainText}>
                If you don’t currently have an electric car, we have a limited number of cars available at very special
                rate from our car subscription partner <a href="https://on.to" className={classes.blueLink}>ONTO</a>.
                This offer is valid for the duration of the Agile Streets trial across the four participating
                councils and is not cumulative with other Onto promotions. A limited number of people who successfully
                meet the trial criteria will receive up to £200 off for the first 2 months subscription to an Onto
                electric car. After that, people will receive £50 off the first month. The cars are subject to
                availability, and delivery and collection fees are not included.
              </p>
              <p className={classes.plainText}>
                Please ﬁll in the registration form and we’ll be in touch with more details about the subscription.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container id="howToSignUp" justifyContent="center" className={classes.blueBackground}>
        <Grid item sm={10} container direction="column">
          <p className={classes.title}>
            What we'll need from you
          </p>
          <p className={classes.plainText}>
            Because we are offering such a great deal for the electric car subscription, any participants who rent cars for the trial will need to agree to using the
            chargepoints to charge their cars at least twice a month. So please bear this in mind if you don’t live or work close to one of the
            locations.
          </p>
          <p className={classes.plainText}>
            Participants will also need to agree to give us feedback during the trials by taking part in any surveys that are sent out as part of our
            monitoring and evaluation for the project. We may also get in touch to request more personalised feedback for use in any testimonials
            or case studies, with your consent.
          </p>
          <p className={classes.boldText}>
            To take part please click on "Register now", enter your details and we'll be in touch! If you have any problems
            registering or have any other questions please email <wbr/>
            <a href="mailto:info@agilestreets.com" className={classes.link}>info@agilestreets.com</a>
          </p>
        </Grid>
        <Grid container justifyContent="center" className={classes.buttonRegisterWrapper}>
          <Button className={classes.buttonRegister} onClick={() => dispatch(toggleRegistrationDialog())}>
            Register now!
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles))(HowToSignUp);