// external
import React, {useCallback, useEffect, useRef, useState} from 'react';
import { compose } from 'redux';
import { orderBy } from 'lodash';

import {useMediaQuery, useTheme} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles/index';

// internal
import FirstStep from '../components/Steps/FirstStep';
import SecondStep from '../components/Steps/SecondStep';
import ThirdStep from '../components/Steps/ThirdStep';
import FourthStep from '../components/Steps/FourthStep';
import StepsProgress from '../components/StepsProgress';
import { DIALOG_TIMEOUT, START_STEP_NUMBER } from '../constants';

const styles = theme => ({
  iconButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    fontSize: 18,
    color: '#555',
    fontFamily: 'Comfortaa',
    fontWeight: 'bold',
    margin: '20px auto',
    textAlign: 'center',
  },
  dialogContent: {
    padding: 0,
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15,
  },
  resourceIcon: {
    width: 30,
    height: 35,
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide ref={ref} direction="up" {...props} mountOnEnter unmountOnExit timeout={DIALOG_TIMEOUT} />
));

const RegistrationDialog = ({
  classes, isOpened, onClose, titleIcon, onSubmit, setBlockFocused, openTermsAndConditions
}) => {
  const [currentStep, setCurrentStep] = useState(START_STEP_NUMBER);
  const [registrationData, setRegistrationData] = useState({
    step1: ''
  });
  const [formCompleted, setFormCompleted] = useState(false);
  const [formClosedAfterSubmit, setFormClosedAfterSubmit] = useState(false);

  useEffect(() => {
    if (formCompleted) {
      (async() => {
        const successfullySubmitted = await onSubmit(registrationData);
        if (successfullySubmitted) {
          const currentStepStepperData = stepperData.current.find(stepData => stepData.step === currentStep);

          if (currentStepStepperData) {
            currentStepStepperData.completed = true;
          }
          setCurrentStep(prevStep => prevStep + 1);
        }
      })();
      setFormCompleted(false)
    }
  }, [formCompleted, onSubmit, registrationData, currentStep])

  useEffect(() => {
    if (formClosedAfterSubmit && !isOpened) {
      setTimeout(() => {
        setRegistrationData({});
        setCurrentStep(START_STEP_NUMBER)
        stepperData.current.forEach(stepData => stepData.completed = false);
        setFormClosedAfterSubmit(false)
      }, DIALOG_TIMEOUT)
    }
  }, [formClosedAfterSubmit, isOpened])

  const handleClose = useCallback(() => {
    onClose();
    if (currentStep===4) {
      setFormClosedAfterSubmit(true);
    }
  }, [currentStep, onClose])

  const stepRef = useRef(null);

  const updateStoreData = useCallback((name, data) => {
    setRegistrationData(prevRegistrationData => ({
      ...prevRegistrationData,
      [name]: data,
    }));
  }, []);

  const getStoreData = useCallback(dataKey => registrationData[dataKey], [registrationData]);

  const onPrevStep = useCallback(() => {
    setCurrentStep(prevStep => prevStep - 1);
  }, []);

  const onNextStep = () => {
    if (currentStep===3) {
      setFormCompleted(true)
    } else {
      const currentStepStepperData = stepperData.current.find(stepData => stepData.step === currentStep);

      if (currentStepStepperData) {
        currentStepStepperData.completed = true;
      }
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const goToStep = useCallback(targetStep => {
    if (currentStep!==4) {
      setCurrentStep(targetStep);
    }
  }, [currentStep]);

  const commonStepsProps = {
    getStoreData: getStoreData,
    updateStoreData: updateStoreData,
    onPrev: onPrevStep,
    onSubmit: onNextStep,
  };

  const steps = [
    {
      name: 'step1',
      step: 1,
      stepperLabel: 'Step 1',
      component: (
        <FirstStep
          stepName="step1"
          setBlockFocused={setBlockFocused}
          onClose={onClose}
          {...commonStepsProps}
        />
      ),
    },
    {
      name: 'step2',
      step: 2,
      stepperLabel: 'Step 2',
      component: (
        <SecondStep
          ref={stepRef}
          stepName="step2"
          localAuthority={registrationData.step1}
          {...commonStepsProps}
        />
      ),
    },
    {
      name: 'step3',
      step: 3,
      stepperLabel: 'Step 3',
      component: (
        <ThirdStep
          ref={stepRef}
          stepName="step3"
          openTermsAndConditions={openTermsAndConditions}
          {...commonStepsProps}
          onSubmitForm={() => onSubmit(registrationData)}
        />
      ),
    },
    {
      name: 'step4',
      step: 4,
      stepperLabel: 'Complete',
      component: (
        <FourthStep
          stepName="step4"
          {...commonStepsProps}
          onClose={handleClose}
        />
      ),
    },
  ];

  const stepperData = useRef(orderBy(steps, ['step'], ['asc']).map(stepData => (
    {
      step: stepData.step,
      label: stepData.stepperLabel,
      completed: false,
    }
  )));

  const handleNavigateToStep = step => async () => {
    if (step === currentStep) return;

    if ((step > currentStep) && (currentStep !== 1)) {
      const isStepValid = await stepRef.current.isValid();

      if (!isStepValid) {
        stepRef.current.onSubmitClick();
        return;
      }
      stepRef.current.saveData();
    }

    goToStep(step);
  };

  const renderStep = () => {
    const currentStepData = steps.find(stepData => stepData.step === currentStep);

    return currentStepData ? currentStepData.component : null;
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={isOpened}
      aria-labelledby="registration-dialog-title"
      TransitionComponent={Transition}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <DialogTitle id="registration-dialog-title" style={{ padding: 0 }} disableTypography>
        <div className={classes.titleBlock}>
          {titleIcon && (
            <img src={titleIcon} alt="resource" className={classes.resourceIcon}/>
          )}
          <Typography variant="h6" className={classes.dialogTitle}>
            New registration request
          </Typography>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <StepsProgress
          steps={stepperData.current}
          activeStep={currentStep}
          handleNavigateToStep={handleNavigateToStep}
          showStepLabel
        />
        {renderStep()}
      </DialogContent>
    </Dialog>
  );
};

export default compose(
  withStyles(styles),
)(RegistrationDialog);
