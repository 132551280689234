// external
import React from 'react';
import { compose } from 'redux';

import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from "@material-ui/icons/Close";

// internal
import {LANDING_PAGE_ANCHORS, LANDING_PAGE_MENU_HEIGHT, PRIMARY_COLOR} from '../constants';
import logo from '../../../images/LandingPage/logo.png';

const styles = theme => ({
  root: {
    flexGrow: 1,
    fontFamily: 'Comfortaa',
  },
  logo: {
    width: 162,
    height: 42,
    padding: '4px 0',
    borderRadius: 4,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#c8f1fa',
    },
  },
  drawerPaper: {
    width: '100%',
    maxWidth: 400,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    borderBottom: '1px solid #b5b5b5',
  },
  menuTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#636363',
    fontFamily: 'Comfortaa',
  },
  menuList: {
    marginTop: 20,
    padding: '0 45px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
    },
  },
  appBarRoot: {
    boxShadow: 'none',
    backgroundColor: PRIMARY_COLOR
  },
  toolbarRoot: {
    height: LANDING_PAGE_MENU_HEIGHT,
    paddingLeft: 60,
    paddingRight: 60,
    [theme.breakpoints.down('md')]: {
      paddingRight: 36,
      paddingLeft: 36,
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 8,
    },
  },
  navigateButton: {
    color: '#fff',
    fontSize: 13,
    fontFamily: 'Comfortaa',
    fontWeight: 'bold',
  },
  loginButton: {
    width: 135,
    color: '#fff',
    fontSize: 13,
    marginRight: 15,
  },
  registerButton: {
    height: 42,
    width: 145,
    color: PRIMARY_COLOR,
    backgroundColor: 'white',
    fontSize: 13,
    fontFamily: 'Comfortaa',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#c8f1fa',
    },
    "&:active": {
      lineHeight: 1,
      letterSpacing: '0.029em',
    }
  },
});

class LandingTopBar extends React.Component {
  state = {
    mobileOpen: false,
  };

  onMobileNavigate = (targetName) => {
    const { onNavigateButtonClick } = this.props;
    this.setState({ mobileOpen: false }, () => {
      onNavigateButtonClick(targetName);
    });
  };

  generateMobileMenuButtons = () => {
    const {
      onRegisterButtonClick, navigationButtons,
    } = this.props;
    const defaultButtons = [
      (
        <ListItem
          button
          key="register"
          disableGutters={true}
          onClick={() => {
            this.setState({ mobileOpen: false }, onRegisterButtonClick);
          }}
        >
          <ListItemText
            primary="REGISTER NOW!"
            primaryTypographyProps={{
              style: {
                fontWeight: 'bold',
                fontSize: 15,
                fontFamily: 'Comfortaa',
                color: PRIMARY_COLOR,
              }
            }}
          />
        </ListItem>
      ),
    ];
    const buttons = navigationButtons.reduce((res, content) => {
      const getComponent = () => (
        <ListItem
          button
          key={content.label}
          disableGutters={true}
          onClick={() => {
            this.onMobileNavigate(content.targetName);
          }}
        >
          <ListItemText
            primary={content.label.toUpperCase()}
            primaryTypographyProps={{
              style: {
                fontWeight: 'bold',
                fontSize: 15,
                fontFamily: 'Comfortaa',
              }
            }} />
        </ListItem>
      );
      res.push(getComponent());
      return res;
    }, []);

    return [buttons, defaultButtons];
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const {
      classes, onRegisterButtonClick, onNavigateButtonClick, navigationButtons
    } = this.props;
    const { mobileOpen } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBarRoot}>
          <Toolbar className={classes.toolbarRoot}>
            <Grid container justifyContent="space-between" alignItems="center">
              <img
                src={logo}
                alt="Agile Streets"
                className={classes.logo}
                onClick={() => { onNavigateButtonClick(LANDING_PAGE_ANCHORS.TOP_OF_PAGE); }}
              />
              <Hidden lgUp>
                <Grid item xs={3} container justifyContent="flex-end">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              <Hidden mdDown>
                <Grid container item lg={8}>
                  <Grid container alignItems="center" justifyContent="space-around">
                    {navigationButtons.map(button => (
                      <Button
                        key={button.label}
                        className={classes.navigateButton}
                        onClick={() => { onNavigateButtonClick(button.targetName); }}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </Grid>
                </Grid>
                <Grid container item lg={2}>
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <Button variant="contained" disableElevation={true} className={classes.registerButton} onClick={onRegisterButtonClick}>
                      Register Now!
                    </Button>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
            <Drawer
              variant="temporary"
              anchor="right"
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Grid container className={classes.toolbar} alignItems="center">
                <Grid item xs={11} container justifyContent="center">
                  <Typography className={classes.menuTitle} align="center" noWrap>
                    Menu
                  </Typography>
                </Grid>
                <Grid item xs={1} container justifyContent="flex-end">
                  <IconButton color="inherit" onClick={this.handleDrawerToggle} aria-label="Close">
                    <CloseIcon/>
                  </IconButton>
                </Grid>
              </Grid>
              <List className={classes.menuList}>
                {this.generateMobileMenuButtons()}
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default compose(withStyles(styles))(LandingTopBar);
