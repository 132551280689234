// external
import React, {useCallback, useState} from 'react';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
// internal
import { MODAL_BUTTONS_STYLES } from "../../constants";
import {AUTHORITIES, AUTHORITIES_CONFIG, LANDING_PAGE_ANCHORS} from "../../../landing-page/constants";

const styles = theme => ({
  ...MODAL_BUTTONS_STYLES,
  mainWrapper: {
    padding: '25px 50px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  gridList: {
    margin: '25px 0 30px !important',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexWrap: 'nowrap',
  },
  gridListTile: {
    transition: '0.4s ease-out',
    '&:hover': {
      backgroundColor: 'rgba(13,68,7,0.4)',
    }
  },
  selectedTile: {
    backgroundColor: 'rgba(13,68,7,0.6)',
  },
  dialogText: {
    fontSize: 24,
    color: '#555',
    margin: '20px auto',
    textAlign: 'center',
    fontFamily: 'Comfortaa',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  errorMessage: {
    textAlign: 'center',
    color: '#f44336',
  },
  navigationContainer: {
    marginTop: theme.spacing(5),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  link: {
    color: '#0077c8',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: `#0077c8cc`,
    },
  }
});

const authoritiesList = [AUTHORITIES.HACKNEY, AUTHORITIES.EAST_LOTHIAN, AUTHORITIES.SHROPSHIRE, AUTHORITIES.GLASGOW];

const FirstStep = ({ classes, getStoreData, onSubmit, stepName, updateStoreData, setBlockFocused, onClose }) => {
  const [localAuthority, setLocalAuthority] = useState(() => {
    const storeData = getStoreData(stepName);

    return storeData ? storeData : null;
  });
  const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

  const selectLocalAuthority = useCallback(authority => {
    setLocalAuthority(authority);
    updateStoreData(stepName, authority);
    onSubmit();
    if (isErrorMessageVisible) {
      setIsErrorMessageVisible(false);
    }
  }, [isErrorMessageVisible, onSubmit, stepName, updateStoreData]);

  return (
    <div className={classes.mainWrapper}>
      <Typography className={classes.dialogText}>
        Select the Local Authority where you'd like to register*
      </Typography>
      <ImageList rowHeight="auto" cols={3} className={classes.gridList}>
        {authoritiesList.map(authority => {
          const config = AUTHORITIES_CONFIG[authority];
          const classesToSet = localAuthority === authority
            ? `${classes.gridListTile} ${classes.selectedTile}`
            :  classes.gridListTile;

          return <ImageListItem
            key={config.dialogLogo}
            className={classesToSet}
            style={{ width: `${config.logoWidth}`, padding: '4px 4px 0 4px', }}
            onClick={() => {selectLocalAuthority(authority)}}
          >
            <img
              src={config.dialogLogo}
              alt={authority}
              style={{ width: '100%'}}
            />
          </ImageListItem>;
        })}
      </ImageList>
      {
        isErrorMessageVisible && (
          <div className={classes.errorMessage}>Please, select your Local Authority.</div>
        )
      }
      <Typography className={classes.dialogText}>
        * Whilst anyone can sign-up, please check&nbsp;
        <span
          className={classes.link}
          onClick={() => {
            onClose();
            setBlockFocused(LANDING_PAGE_ANCHORS.LOCATIONS);
          }}
        >
          locations
        </span>
        &nbsp;to make sure you'll be able to use one of the charge points regularly
      </Typography>
    </div>
  );
};

export default compose(withStyles(styles))(FirstStep);
