export const DIALOG_TIMEOUT = 500;

export const START_STEP_NUMBER = 1;

export const USER_GENDER = {
  male: 'Male',
  female: 'Female',
};

export const USER_AGE_RANGE = {
  youngest: 18,
  oldest: 99,
};

export const YES_NO_VALUE_LABEL_MAP = {
  yes: {
    value: true,
    label: 'Yes',
  },
  no: {
    value: false,
    label: 'No',
  },
};

export const TERMS_AND_CONDITIONS_RADIO_LABELS = {
  yes: 'Accept',
  no: 'Decline',
};

export const MODAL_BUTTONS_STYLES = {
  button: {
    fontFamily: 'Comfortaa',
    fontWeight: 'bold',
  }
};